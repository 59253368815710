export default {
  methods: {
    async readIndexedDBData(payload) {
      return this.indexedDBInstance.retrieveInfo({
        collection: payload.collection,
        id: payload.id,
      });
    },
    saveIndexedDBInfo(payload) {
      this.indexedDBInstance.saveInfo({
        collection: payload.collection,
        info: payload.item,
        id: payload.id,
      });
    },
    async updateIndexedDBInfo(payload) {
      await this.indexedDBInstance.updateInfo({
        collection: payload.collection,
        info: payload.item,
        id: payload.id,
      });
    },
    deleteIndexedDBInfo(payload) {
      this.indexedDBInstance.deleteInfo({
        collection: payload.collection,
        id: payload.id,
      });
    }
  }
};
