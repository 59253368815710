<template>
  <div class="shipping-skeleton">
    <div class="row">
      <div class="col-12">
        <Skeleton height="3rem" class="mb-4"></Skeleton>
        <Skeleton height="1rem" class="mb-3"></Skeleton>
        <Skeleton height="3rem" class="mb-3"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
      <div class="col-8">
        <Skeleton height="1rem" class="mb-1"></Skeleton>
        <Skeleton height="1rem" class="mb-1"></Skeleton>
        <Skeleton height="1rem" class="mb-1"></Skeleton>
        <Skeleton height="1rem" class="mb-2"></Skeleton>
      </div>
      <div class="col-12">
        <Skeleton height="3rem" class="mb-3"></Skeleton>
      </div>
      <div class="col-6">
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
      <div class="col-6">
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
      <div class="col-12">
        <Skeleton height="2rem" class="mb-2"></Skeleton>
        <Skeleton height="4rem" class="mb-3"></Skeleton>
      </div>
    </div>
    <div class="row justify-content-center">
       <div class="col-sm-4 col-md-12 col-lg-12">
        <Skeleton height="3rem" class="mb-4"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
import onReSize from "../../../../Extend/onResize";

export default {
  name: "StopInformationSkeleton",
  components: {
    Skeleton,
  },
  mixins: [onReSize],
  data() {
    return {
      screenWidth: null,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
