<!-- eslint-disable func-names -->
<template>
  <div class="tender-confirmation">
    <div class="tender-confirmation__header">
      <div class="tender-confirmation__title">
        <h3>{{ $t("stopsInfo.oneMore") }}</h3>
      </div>
      <div class="tender-confirmation-button">
        <button @click="$emit('input', false)" class="close-button d-flex flex-row-reverse">
          <i class="ion ion-close-circle-outline close-button__icon"></i>
        </button>
      </div>
    </div>
    <hr />
    <div class="tender-confirmation__content">
      <span>{{ $t("stopsInfo.confirmInfo") }}</span>
      <DragAndDrop
        :files="files"
        :selectTypeProblem="selectTypeProblem"
        :selectDocuments="selectDocuments"
        :documentsCategory="documentsCategory"
        ref="accessorialsDocuments"
      />
      <div class="documents-information">
        {{ $t("stopsInfo.selectedCarrier") }}: <br />
        <span class="documents-information__text">
          {{ selectedCarrier.carrier || selectedCarrier[0].carrier }}</span
        >
      </div>
      <div class="documents-information">
        {{ $t("stopsInfo.priceCarrier") }}: <br />
        <span v-if="!isInsurance" class="documents-information__text">
          <span v-if="!isPickup">
            USD ${{ formatEasyPostPriceWithoutPickup(selectedCarrier || selectedCarrier[0]) }}
          </span>
          <span v-else>
            USD ${{
              formatEasyPostPriceWithPickup(
                selectedCarrier.quoteContractId || selectedCarrier[0].quoteContractId
              )
            }}
          </span>
        </span>
        <span class="documents-information__text" v-else>
          <span v-if="!isPickup">
            USD ${{
              formatEasyPostPriceWithoutPickup(selectedCarrier || selectedCarrier[0]) +
              aditionalInsurance
            }}
          </span>
          <span v-else>
            USD ${{
              formatEasyPostPriceWithPickup(
                selectedCarrier.quoteContractId || selectedCarrier[0].quoteContractId
              ) + aditionalInsurance
            }}
          </span>
        </span>
      </div>
      <div class="documents-information" v-if="chosenLoad.insurance_required">
        <span>{{ $t("carriers.modalInfo.insurance") }}: </span> <br />
        <span v-if="chosenLoad.cargo_value_for_insurance" class="documents-information__text">
          Insured, cargo value: ${{ chosenLoad.cargo_value_for_insurance }}
        </span>
        <span v-else class="documents-information__text"> Up to Cargo Value $25000 </span>
      </div>
      <div class="documents-information" v-if="accessorials.length">
        <el-collapse v-model="accessorialsTab">
          <el-collapse-item :title="$t('stopsInfo.AccessorialsIncluded')" name="accessorials">
            <AccessorialsByType v-if="accessorials" :accessorials="accessorials" />
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="documents-information">
        {{ $t("carriers.modalInfo.portalNotes") }}: <br />
        <span class="documents-information__text">
          <p class="documents-information__text documents-information__text--portal-notes">
            {{ portalNotes }}
          </p>
        </span>
      </div>
      <TermsAndConditionsVue v-if="!isBranded" />
      <!-- <div class="daily-pickup" v-if="!loadIsFtl">
        <b-form-checkbox
          v-model="dailyPickup"
          :binary="true"
          @click.stop
          @click.prevent
          class="mr-2"
          id="daily-pickup"
        />
        <label for="daily-pickup">The current origin has daily pickup with the carrier.</label>
      </div> -->
      <div
        class="tender-confirmation__button-container"
        v-if="$options.filters.checkPermission('post:tender')"
      >
        <b-overlay :show="requestInProgress" rounded="sm">
          <button
            @click="tender"
            :class="requestInProgress ? 'disabled-button' : ''"
            class="tender-confirmation__button"
          >
            {{ $t("stopsInfo.tender") }}
          </button>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable implicit-arrow-linebreak */
import { mapGetters } from "vuex";
import FormatEasyPostPrices from "@/Extend/FormatEasyPostPrices";
import IndexedDB from "../../../../Extend/IndexedDB";
import DragAndDrop from "../../../../components/DragAndDropFiles.vue";
import TermsAndConditionsVue from "../../shared/TermsAndConditions.vue";
import AccessorialsByType from "../../../../components/AccessorialsByType.vue";
import CategoryTender from "../../../../Extend/CategoryTender";
import formatterCarrierName from "../../../../Extend/FormatCarrierName";
import DexieDb from "../../../../utils/db";
import UpdateInsurance from "../../../../utils/updateAditionalInsurance";
import modes from "../../modes";
import FilterEasyPostCarriers from "../../../../Extend/FilterEasyPostCarriers";

export default {
  name: "TenderConfirmation",
  emits: ["confirmationTender"],
  mixins: [
    CategoryTender,
    IndexedDB,
    formatterCarrierName,
    FormatEasyPostPrices,
    FilterEasyPostCarriers,
  ],
  props: {
    chosenLoad: Object,
    pickupStopInfo: Object,
    selectedCarrier: Object,
    carriersAndTrucks: Object,
  },
  components: {
    DragAndDrop,
    AccessorialsByType,
    TermsAndConditionsVue,
  },
  data() {
    return {
      accessorials: null,
      accessorialsTab: ["accessorials"],
      insuranceInfo: null,
      loading: false,
      formData: null,
      files: [],
      selectTypeProblem: [],
      selectDocuments: [],
      documentsCategory: [],
      creatingTender: false,
      aditionalInsurance: 0,
      portalNotes: "",
      dailyPickup: false,
      isBranded: false,
      isPickup: null,
      isInsurance: null,
      originalCarrierArray: null,
    };
  },
  created() {
    this.selectDocuments = this.categoryTender;
    this.isPickup = this.$route.query.pickup;
    this.isInsurance = this.$route.query.insurance;
    this.accessorials = this.chosenLoad.accessorials;
    this.setCarrierInformation();
    if (this.isInsurance) {
      this.calculateAdicionalInsurance();
    }
  },
  computed: {
    ...mapGetters({
      requestInProgress: "load/tender/getRequestInProcess",
      transportModalInfo: "transportSelected/getTransportModalInformation",
      carriersList: "carriers/getCarriers",
    }),
    loadIsFtl() {
      return this.chosenLoad.mode_id === modes.ftl.modeId;
    },
  },
  watch: {
    carriersList: {
      handler() {
        this.originalCarrierArray = [...this.carriersList];
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async setCarrierInformation() {
      const allCarrierAlerts = this.$store.getters["carriersAlert/getAllCarriersAlert"];
      const foundCarrierAlert = allCarrierAlerts.find(
        (el) => this.formatterCarrierName(el.carrier_name) === this.selectedCarrier.carrier
      );
      const portalNotes = foundCarrierAlert?.description?.en || "";
      this.portalNotes = this.selectedCarrier.thresholds || portalNotes;
    },
    async tender() {
      if (this.$options.filters.checkPermission("post:tender")) {
        this.creatingTender = true;
        this.loading = true;
        this.$emit("changeLoadingTender", {
          loadingType: "creatingTender",
          value: true,
        });
        this.$emit("changeLoadingTender", {
          loadingType: "loadingTender",
          value: true,
        });
        if (this.validateFilesList()) {
          await this.uploadDocuments();
        }
        const response = await this.tenderFunction();
        this.creatingTender = false;
        this.loading = false;
        this.$emit("changeLoadingTender", {
          loadingType: "creatingTender",
          value: false,
        });
        this.$emit("changeLoadingTender", {
          loadingType: "loadingTender",
          value: false,
        });
        this.$emit("input", false);
        if (response && response.status === 200) {
          this.$store.dispatch("load/tender/joinToTenderSocket", this.$route.params.loadId);
          this.$store.commit("load/setChosenLoadDetailsView", response.data);
          this.$router.push({
            name: "LoadsDetails",
            params: {
              loadId: this.$route.params.loadId,
            },
          });
        }
      }
      this.deleteIndexedDbCollectionsInformation();
    },
    deleteIndexedDbCollectionsInformation() {
      DexieDb.deleteFromDb({
        collection: "tenderReferences",
        id: this.$route.params.loadId,
      });
      DexieDb.deleteFromDb({
        collection: "tenderLineItems",
        id: this.$route.params.loadId,
      });
      DexieDb.deleteFromDb({
        collection: "lineItems",
        id: this.$route.params.loadId,
      });
    },
    async uploadDocuments() {
      const { _id } = this.selectedCarrier;
      this.formData = new FormData();
      this.formData.append("load_id", this.$route.params.loadId);
      this.formData.append("carrier", _id);
      this.documentsCategory.forEach((item) => {
        this.formData.append("categories", item);
      });
      this.files.forEach((file) => {
        this.formData.append("raw_files", file.raw);
      });
      return this.$store.dispatch("load/tender/uploadFile", this.formData);
    },
    addMarkup(carrierPrice) {
      const { markupMin, markupPercent } = this.carriersAndTrucks.carriers[0];
      let magayaMarkup = (carrierPrice * markupPercent) / 100;
      if (magayaMarkup < markupMin) {
        magayaMarkup = markupMin;
      }
      const price = carrierPrice + magayaMarkup;
      return parseFloat(price.toFixed(2));
    },
    async tenderFunction() {
      // check if its a truck or carrier
      const param = this.$route.params.carrierId;
      let isCarrier = true;
      let truckName = null;
      if (param.includes("truck=")) {
        isCarrier = false;
        truckName = param.substring(param.indexOf("=") + 1);
      }
      const isMagayaAccount = this.$store.getters["login/getTokenInfo"].magaya_guid;
      this.isBranded = this.$store.getters["login/getTokenInfo"].is_branded;
      const shippingInfo = this.pickupStopInfo.shippingData;
      const temperature = this.$store.getters["load/lineItems/getLineItemsTemperatureToSend"];
      const insuranceValue = this.$route.query.insurance;
      const pickupValue = this.$route.query.pickup;
      const data = {
        id: this.$route.params.loadId,
        body: {
          carrier_id: isCarrier ? this.$route.params.carrierId : null,
          truck: !isCarrier ? truckName.toLowerCase() : null,
          insurance_required: insuranceValue,
          ...(this.$route.query.loyaltyInsurance && {
            loyalty_insurance: this.$route.query.loyaltyInsurance,
          }),
          temperature_setting: temperature,
          ...(isMagayaAccount && {
            ground_shipment: this.$store.getters["load/getGroundShipment"],
          }),
          buy_pickup: pickupValue,
        },
        params: {
          pickup_shipping_receiving_hours: `${shippingInfo.firstHour}-${shippingInfo.secondHour}`,
        },
      };
      this.$store.commit("load/lineItems/setLineItemsTemperature", 0);
      this.$store.commit("load/lineItems/setTemperatureToSendValue", 0);
      return this.$store.dispatch("load/tender/createTender", data);
    },
    validateFilesList() {
      let validation = true;
      if (!this.files.length) {
        validation = false;
      }
      return validation;
    },
    calculateAdicionalInsurance() {
      this.aditionalInsurance = UpdateInsurance(this.chosenLoad.cargo_value_for_insurance);
    },
  },
};
</script>

<style lang="scss" scoped>
///****Import styles****\\\\
@import "../../../../assets/scss/components/Dropdown.scss";
@import "../../../../assets/scss/components/Checkboxes.scss";

.close-button {
  @include modals-close-button;
}
.tender-confirmation {
  @include font-standart-text;
  width: 100%;
  color: $color-primary-company;
  font-family: $font-family-portal;
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__content span {
    margin-bottom: 20px;
  }
  &__button-container {
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.tender-confirmation__button {
  @include primary-button(35px);
  padding: 7px 30px;
  @include font-button-text;
  font-weight: 400;
}
hr {
  margin-top: 0;
}
::v-deep {
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
  .el-collapse-item__header {
    width: 100%;
    text-align: left;
    font-weight: bold;
    height: 30px;
    font-size: 16px !important;
  }
  .el-icon-arrow-right {
    color: $color-primary-company;
    font-weight: bold;
  }
}

.disabled-button {
  background-color: $color-gray-dark;
}
.documents-information {
  @include font-standart-text;
  color: $color-primary-company;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  &__text {
    font-weight: normal;
    &--portal-notes {
      margin: 0px;
    }
  }
}
.accessorials-info {
  margin-top: 10px;
}

.daily-pickup {
  display: flex;
  margin-top: 20px;
}
</style>
