<template>
  <div class="hazmat-checkbox">
    <Checkbox
      :binary="true"
      v-model="isHazmat"
      @change="checkboxHandling"
      :disabled="updatingRequestInProcess"
    />
    <span v-if="itemIsHazmat && hazmatInfoFilled">
      <i @click="openHazmatModal()" class="ion ion-create-outline"></i>
    </span>
    <b-modal
      v-model="showHazmatModal"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
      centered
      @hide="closeHazmatModal"
    >
      <HazmatModal
        v-model="hazmatData"
        :hazmatData="hazmatData"
        @closeModal="closeHazmatModal"
        @saveHazmatInformation="saveHazmatInformation"
        ref="hazmat-modal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Checkbox from "primevue/checkbox";
import HazmatModal from "./HazmatModal.vue";

export default {
  /* eslint-disable operator-linebreak */
  name: "HazmatCheckbox",
  props: {
    hazmatIndex: Number,
    itemIsHazmat: Boolean,
    hazmat: Object,
  },
  components: {
    Checkbox,
    HazmatModal,
  },
  data() {
    return {
      isHazmat: false,
      showHazmatModal: false,
      hazmatData: null,
      loadId: null,
      hazmatInfoFilled: false,
    };
  },
  created() {
    this.loadId = this.$route.params.loadId;
    this.hazmatData = { ...this.hazmat };
    const hazmatFieldsInfo = this.checkIfHazmatIsFilled();
    if (this.itemIsHazmat && hazmatFieldsInfo) {
      this.isHazmat = true;
    }
  },
  computed: {
    ...mapGetters({
      updatingRequestInProcess: "load/tender/getRequestInProcess",
    }),
  },
  watch: {
    hazmatData: {
      deep: true,
      immediate: true,
      handler() {
        this.hazmatInfoFilled = this.checkIfHazmatIsFilled();
      },
    },
    hazmat: {
      handler() {
        this.hazmatData = { ...this.hazmat };
        this.isHazmat = this.checkIfHazmatIsFilled();
      },
    },
  },
  methods: {
    checkIfHazmatIsFilled() {
      let alInformationIsFilled = true;
      if (this.hazmatData) {
        Object.keys(this.hazmatData).forEach((key) => {
          if (!this.hazmatData[key]) {
            alInformationIsFilled = false;
          }
        });
      } else {
        alInformationIsFilled = false;
      }
      this.hazmatInfoFilled = alInformationIsFilled;
      return alInformationIsFilled;
    },
    async checkboxHandling() {
      if (!this.isHazmat) {
        const payload = {};
        if (this.hazmatData) {
          Object.keys(this.hazmatData).forEach((key) => {
            payload[key] = "";
          });
          this.hazmatData = {};
        }
        this.$emit("removeHazmatInformation", payload, this.hazmatIndex);
        return;
      }
      this.openHazmatModal();
    },
    openHazmatModal() {
      if (!this.updatingRequestInProcess) {
        this.showHazmatModal = true;
      }
    },
    closeHazmatModal() {
      if (!this.checkIfHazmatIsFilled()) {
        this.isHazmat = false;
        this.hazmatData = null;
      }
      this.showHazmatModal = false;
    },
    saveHazmatInformation() {
      this.showHazmatModal = false;
      this.hazmatData.hazmat_contact = process.env.VUE_APP_HAZMAT_ID_DEV;
      this.$emit("addHazmatInformation", this.hazmatData, this.hazmatIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.hazmat-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 7px;
    color: #7a7a7a;
  }
  i {
    cursor: pointer;
    color: $color-border-container;
    font-size: 19px;
  }
}
.is-active {
  margin-left: 30px;
}
::v-deep .p-checkbox .p-checkbox-box {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid $color-border-container;
}
::v-deep .p-checkbox .p-checkbox-box.p-highlight {
  background: $color-primary-trigger-button;
  border-color: $color-border-container;
  &:hover {
    background: $color-border-container;
    background-color: $color-border-container;
    border-color: $color-border-container;
  }
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: $color-border-container;
  box-shadow: none;
}
::v-deep .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $color-border-container;
}
::v-deep .floatlabel-input {
  border: 1px solid $color-border-container;
}
::v-deep .modal-content {
  width: 55% !important;
  margin: auto;
  @media (max-width: 798px) {
    width: 80% !important;
  }
}
// modal styles
::v-deep .modal-content {
  border-radius: 10px;
  background-color: $color-gray-light;
}
::v-deep .modal-body {
  padding: 0;
  border-radius: 10px;
}
</style>
