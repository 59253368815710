export default {
  methods: {
    ceilDecimals(decimal) {
      return Math.ceil(decimal);
    },
    getHazmatInformation() {
      this.hazmatInformation = this.completeData.map((item) => {
        const hazmat = {
          hazmat_class_division: item.hazmat_class_division,
          hazmat_number_type: item.hazmat_number_type,
          hazmat_packaging_group: item.hazmat_packaging_group,
          hazmat_number: item.hazmat_number,
        };
        return hazmat;
      });
    },
    searchItemsWithHazmat(data) {
      if (data) {
        this.lineItems = [...data];
      }
      this.isAnyItemWithHazmat = this.lineItems.some((item) => item.hazardous_materials);
      return this.isAnyItemWithHazmat;
    },
    showHazmatError(payload) {
      this.hazmatError = payload;
    },
    isRequiredHazmat(data) {
      this.$emit("validateHazmatInformation", data);
    },
    findDefaultHazmatItem() {
      const lineItems = this.$store.getters["load/getChosenLoad"].line_items;
      const defaulHazmatItem = lineItems.find((item) => {
        let foundItem = null;
        if (item.hazardous_materials && !this.checkIfHazmatInfoWasFilled(item)) {
          foundItem = item;
        }
        return foundItem;
      });
      if (defaulHazmatItem) {
        const payload = {
          id: this.$route.params.loadId,
          lineItemId: defaulHazmatItem.line_item_id,
          body: {
            hazardous_materials: false,
          },
        };
        return this.$store.dispatch("load/lineItems/updatedLineItem", payload);
      }
      return null;
    },
    async updateHazmatInformation(data, index) {
      if (this.$options.filters.checkPermission("patch:line-item")) {
        this.$store.commit("load/tender/setRequestInProcess", true);
        const payload = {
          id: this.$route.params.loadId,
          lineItemId: this.lineItems[index].line_item_id,
          body: {
            hazardous_materials: this.lineItems[index].hazardous_materials,
          },
        };
        Object.keys(data).forEach((key) => {
          payload.body[key] = data[key];
        });
        await this.$store.dispatch("load/lineItems/updatedLineItem", payload);
        this.$store.commit("load/tender/setRequestInProcess", false);
      }
    },
    checkIfHazmatInfoWasFilled(item) {
      const hazmatVariables = [
        "hazmat_class_division",
        "hazmat_number_type",
        "hazmat_packaging_group",
        "hazmat_number",
      ];
      let hazmatInfoFilled = true;
      hazmatVariables.forEach((variable) => {
        if (!item[variable]) {
          hazmatInfoFilled = false;
        }
      });
      return hazmatInfoFilled;
    },
    findItemWithHazmatInfo(isHazmat) {
      if (typeof isHazmat === "undefined" && !isHazmat) {
        return false;
      }
      const hazmatVariables = [
        "hazmat_class_division",
        "hazmat_number_type",
        "hazmat_packaging_group",
        "hazmat_number",
      ];
      let infoWasFilled = false;
      for (let i = 0; i < this.data.length; i += 1) {
        for (let j = 0; j < hazmatVariables.length; j += 1) {
          if (this.data[i][hazmatVariables[j]]) {
            infoWasFilled = true;
            break;
          }
        }
        if (infoWasFilled) {
          break;
        }
      }
      this.isAnyItemWithHazmat = infoWasFilled;
      return infoWasFilled;
    },
    validateIfNmfcFieldwasFilled() {
      let itemNumber;
      let infoWasFilled = true;
      for (let i = 0; i < this.data.length; i += 1) {
        if (this.data[i].nmfcFieldEnabled && !this.data[i].nmfc_number) {
          infoWasFilled = false;
          itemNumber = i + 1;
          break;
        }
      }
      return { infoWasFilled, itemNumber };
    },
    updateVuexLineItems(data, index) {
      const lineItemsCopy = JSON.parse(JSON.stringify(this.completeData));
      lineItemsCopy[index] = { ...lineItemsCopy[index], ...data };
      this.$store.commit("load/modifyChosenLoadSpecificData", {
        key: "line_items",
        value: lineItemsCopy,
      });
    },
  }
};
