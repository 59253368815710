export default {
  data() {
    return {
      categoryTender: [
        {
          text: this.$t("stopsInfo.categoryTenderOption1"),
        },
        {
          text: this.$t("stopsInfo.categoryTenderOption2"),
        },
        {
          text: this.$t("stopsInfo.categoryTenderOption3"),
        },
        {
          text: this.$t("stopsInfo.categoryTenderOption4"),
        },
        {
          text: this.$t("stopsInfo.categoryTenderOption5"),
        },
        {
          text: this.$t("stopsInfo.categoryTenderOption6"),
        },
        {
          text: this.$t("stopsInfo.categoryTenderOption7"),
        },
      ],
    };
  },
};
