<template>
  <div class="account-edit-modal">
    <b-overlay :show="loading" rounded="sm">
      <div class="account-edit__header">
        <div class="account-edit__title">
          <h3>{{ $t("stopsInfo.accountCreation") }}</h3>
        </div>
        <div class="account-edit__button">
          <button @click="closeModal" class="close-button d-flex flex-row-reverse">
            <i class="ion ion-close-circle-outline close-button__icon"></i>
          </button>
        </div>
      </div>
      <hr />
      <div class="title-container__subtitle">
        {{ mode === "pickup" ? $t("stopsInfo.pickupLocation") : $t("stopsInfo.deliveryLocation") }}
      </div>
      <AccountForm
        :locationInformation="locationInfo"
        :mode="mode"
        v-model="accountData"
        ref="account-data"
      />
      <div class="title-container__subtitle" v-html="$t('stopsInfo.pickupContact')"></div>
      <ContactForm
        :contactInformation="contactInformation"
        :mode="mode"
        previousModal="createContact"
        v-model="contactData"
        :contactsList="contactsList"
        ref="contact-data"
      />
      <div class="checkbox-container">
        <b-form-checkbox
          v-model="locationInfo.createLocationInBd"
          id="checkbox"
          :binary="true"
          @click.stop
          @click.prevent
        />
        <label for="checkbox">
          <span>Save in My Locations</span>
        </label>
      </div>
      <button class="mt-4 button-save" @click="getShippingDetail()">
        {{ $t("stopsInfo.createStopInfo.save") }}
      </button>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import AccountForm from "./AccountForm.vue";
import ContactForm from "./ContactForm.vue";

export default {
  name: "AccountCreationModal",
  components: {
    AccountForm,
    ContactForm,
  },
  props: {
    locationInformation: Object,
    contactInformation: Object,
    shippingInformation: Object,
    mode: String,
  },
  data() {
    return {
      locationInfo: null,
      accountData: null,
      contactData: null,
      contactsList: [],
      loading: false,
    };
  },
  async created() {
    if (this.locationInformation) {
      this.locationInfo = { ...this.locationInformation };
      this.locationInfo.createLocationInBd = false;
    } else {
      const {
        accountAddress,
        appointment_required,
        appointmet_required,
        contact_info_id,
        departure_date,
        instructions,
        location_id,
        references,
        stop_status,
        is_dropoff,
        is_pickup,
        ...rest
      } = this.shippingInformation;
      this.locationInfo = rest;
      this.locationInfo.name = "";
      this.locationInfo.createLocationInBd = false;
    }
  },
  methods: {
    async getShippingDetail() {
      const fieldsAccount = this.$refs["account-data"].validateFormfields();
      const fieldsContact = this.$refs["contact-data"].validateFormfields();
      if (fieldsAccount && fieldsContact) {
        this.loading = true;
        const accountData = await this.createAccount(this.accountData);
        if (this.locationInfo.createLocationInBd) {
          this.$store.commit("load/stops/setNewSalesforceAccount", accountData);
        }
        const contactData = await this.createContact(this.contactData, accountData.id_salesforce);
        this.$emit("getAccountData", accountData);
        this.$emit("getContactData", contactData);
        this.$emit("closeModal", false);
        this.loading = false;
      }
    },
    async createAccount(payload) {
      this.$store.commit("load/tender/setRequestInProcess", true);
      const data = {
        ...payload,
        zip_code: this.locationInfo.zip_code || this.locationInfo.postal_code,
        country_code: this.locationInfo.country_code,
        // ACCOUNT ID ONLY FOR TESTS
        account_id: process.env.VUE_APP_ACCOUNT_ID_TEST,
        createLocationInBd: this.locationInfo.createLocationInBd,
      };
      delete data.addressInformation;
      const response = await this.$store.dispatch("load/stops/createSalesforceAccounts", data);
      this.$store.commit("load/tender/setRequestInProcess", false);
      return response;
    },
    async createContact(payload, salesforceId) {
      if (this.$options.filters.checkPermission("post:salesforce-contact")) {
        const data = {
          ...payload,
          account_id: salesforceId,
        };
        const response = await this.$store.dispatch("load/stops/createSalesforceContacts", data);
        return response;
      }
      return null;
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/Checkboxes.scss";

.close-button {
  @include modals-close-button;
}
.account-edit {
  &-modal {
    font-family: $font-family-portal;
  }
  &__header {
    @include modals-header;
  }
  &__title {
    @include modals-title;
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}
hr {
  margin-top: 0;
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: $color-primary-company;
  margin: 10px auto;
  @include font-section-title;
  &__subtitle {
    @include font-standart-text;
    color: $color-border-container;
    margin: 10px 0px;
    margin-bottom: 18px;
  }
}
.button-save {
  @include primary-button(35px);
  @include font-button-text;
  padding: 5px;
  font-weight: 400;
}
.checkbox-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 7px;
  @media (max-width: 991px) {
    margin: 0px;
  }
  @media (max-width: 500px) {
    margin-left: 5px;
  }
}
.checkbox-container label {
  @include font-standart-text;
  margin: 0;
  margin-top: 5px;
  font-size: 0.98rem;
  vertical-align: middle;
  color: rgb(101, 100, 100);
}

::v-deep .location-information-info__information {
  margin: 0px;
  margin-bottom: 22px;
}

::v-deep .bg-light {
  background-color: $color-gray-light !important;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}

::v-deep {
  @media (max-width: 800px) {
    .floatlabel-input__label {
      top: 12%;
      font-size: 0.875rem;
    }
    .floatlabel {
      top: -25px;
      font-size: 0.75rem;
    }
  }
}
</style>
