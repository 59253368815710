/* eslint-disable max-len */
import * as yup from "yup";
import { convertDimensionsMeasure, convertWeightMeasure } from "../Extend/ConvertToDefaultValues";

const LineItemTenderFTLSchema = yup.object().shape({
  hazardous_materials: yup.bool(),
  linear_feet: yup.string(),
  name: yup.string().required(),
  description: yup.string().required(),
  dimension_units: yup.string().required(),
  height: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("handling_units", {
      is: "Truckloads",
      then: yup.string(),
      otherwise: yup.string().when("quoteMode", {
        is: "ftl",
        then: yup.string().test("height-limit", (value, { createError, parent }) => {
          const height = parseFloat(value);
          if (height > 108) {
            const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 108);
            return createError({
              message: `You have exceeded the height limit. The maximum height allowed is ${limitResult}(${parent.user_dimension_units}).`,
            });
          }
          return true;
        }),
      })
        .when("quoteMode", {
          is: "reefer",
          then: yup.string().test("height-limit", (value, { createError, parent }) => {
            const height = parseFloat(value);
            if (height > 90) {
              const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 90);
              return createError({
                message: `You have exceeded the height limit. The maximum height allowed is ${limitResult}(${parent.user_dimension_units}).`,
              });
            }
            return true;
          }),
        }),
    }),
  width: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("handling_units", {
      is: "Truckloads",
      then: yup.string(),
      otherwise: yup.string().when("quoteMode", {
        is: "ftl",
        then: yup.string().test("width-limit", (value, { createError, parent }) => {
          const width = parseFloat(value);
          const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 102);
          if (width > 102) {
            return createError({
              message: `You have exceeded the width limit. The maximum width allowed is ${limitResult}(${parent.user_dimension_units}).`,
            });
          }
          return true;
        }),
      })
        .when("quoteMode", {
          is: "reefer",
          then: yup.string().test("width-limit", (value, { createError, parent }) => {
            const width = parseFloat(value);
            const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 80);
            if (width > 80) {
              return createError({
                message: `You have exceeded the width limit. The maximum width allowed is ${limitResult}(${parent.user_dimension_units}).`,
              });
            }
            return true;
          }),
        }),
    }),
  length: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("handling_units", {
      is: "Truckloads",
      then: yup.string(),
      otherwise: yup.string().when("quoteMode", {
        is: "ftl",
        then: yup.string().test("length-limit", (value, { createError, parent }) => {
          const length = parseFloat(value);
          const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 636);
          if (length > 636) {
            return createError({
              message: `You have exceeded the length limit. The maximum length allowed is ${limitResult}(${parent.user_dimension_units}).`,
            });
          }
          return true;
        }),
      })
        .when("quoteMode", {
          is: "reefer",
          then: yup.string().test("length-limit", (value, { createError, parent }) => {
            const length = parseFloat(value);
            const limitResult = convertDimensionsMeasure(parent.user_dimension_units, 200);
            if (length > 200) {
              return createError({
                message: `You have exceeded the length limit. The maximum length allowed is ${limitResult}(${parent.user_dimension_units}).`,
              });
            }
            return true;
          }),
        }),
    }),
  weight_units: yup.string().required(),
  weight: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    )
    .when("handling_units", {
      is: "Truckloads",
      then: yup.string(),
      otherwise: yup.string().when("quoteMode", {
        is: "ftl",
        then: yup.string().test("weight-limit", (value, { createError, parent }) => {
          const unitsHandling = parseFloat(parent.handling_unit_count) || 1;
          const weight = parseFloat(value);
          const limitResult = convertWeightMeasure(parent.user_weight_units, 7000);
          if (weight > 7000 * unitsHandling) {
            return createError({
              message: `The maximum weight allowed is ${limitResult}(${parent.user_weight_units}) per unit.`,
            });
          }
          return true;
        }),
      })
        .when("quoteMode", {
          is: "reefer",
          then: yup.string().test("weight-limit", (value, { createError, parent }) => {
            const unitsHandling = parseFloat(parent.handling_unit_count) || 1;
            const weight = parseFloat(value);
            const limitResult = convertWeightMeasure(parent.user_weight_units, 5000);
            if (weight > 5000 * unitsHandling) {
              return createError({
                message: `The maximum weight allowed is ${limitResult}(${parent.user_weight_units}) per unit.`,
              });
            }
            return true;
          }),
        }),
    }),
  handling_units: yup.string().required(),
  handling_unit_count: yup
    .string()
    .required()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => parseFloat(value).toFixed(2) > 0
    ),
  packaging_units: yup.string().nullable(),
  packaging_unit_count: yup
    .string()
    .nullable()
    .test(
      "is-positive",
      "The value must be greater than 0",
      (value) => value === null || parseFloat(value).toFixed(2) > 0
    ),
  user_weight: yup.string(),
  user_weight_units: yup.string(),
  user_length: yup.string(),
  user_width: yup.string(),
  user_height: yup.string(),
  user_dimension_units: yup.string(),
  quoteMode: yup.string().required(),
});

export default LineItemTenderFTLSchema;
