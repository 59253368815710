<template>
  <div class="line-items-data-skeleton">
    <div class="col-12">
      <Skeleton height="2rem" class="mb-2"></Skeleton>
      <Skeleton height="4rem" class="mb-2"></Skeleton>
      <Skeleton height="1rem" class="mb-4"></Skeleton>
    </div>
    <div class="row justify-content-center">
      <div class="col-4">
        <Skeleton height="3rem" class="mb-4"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
import onReSize from "../../../../../Extend/onResize";

export default {
  name: "LineItemsDataSkeleton",
  components: {
    Skeleton,
  },
  mixins: [onReSize],
  data() {
    return {
      screenWidth: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.line-items-data-skeleton {
  padding: 2rem;
}

::v-deep .p-skeleton {
  background-color: #d9d9d9;
}
</style>
