<template>
  <div class="hazmat-error-notification">
    {{ $t('bookALoad.missingFieldsHazmat') }}
  </div>
</template>

<script>
export default {
  name: 'HazmatErrorNotification',
};
</script>

<style lang="scss" scoped>
.hazmat-error-notification {
  width: 100%;
  text-align: start;
  margin: 1rem auto;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgb(248, 203, 203);
  color: rgb(255, 137, 137);
  border-radius: 7px;
  background: $missing-fields;
}
</style>
