<template>
  <div class="load-detail">
    <h4 v-if="screenWidth < 800" class="load-detail__title">Aditional load information</h4>
    <div class="load-detail__content" v-if="renderView === 'Desktop'">
      <div class="information-container">
        <RCInput
          class="information-container__input"
          type="text"
          v-model="load.po_number"
          label="PO number"
          :floatLabel="true"
          @input="updateData"
        />
      </div>
      <div class="information-container">
        <RCInput
          class="information-container__input"
          type="text"
          v-model="load.billing_reference"
          label="Billing reference"
          :floatLabel="true"
          @input="updateData"
        />
      </div>
    </div>
    <div class="load-detail__content" v-if="renderView === 'Responsive'">
      <div class="information-container">
        <div class="information-container__title"><span>PO number:</span></div>
        <RCInput
          class="information-container__input"
          :class="emptyFields['po_number'] === true ? 'missing-field' : ''"
          type="text"
          label="PO number"
          :floatLabel="true"
          v-model="load.po_number"
        />
      </div>
      <div class="information-container information-container--reference">
        <div class="information-container__title"><span>Billing reference:</span></div>
        <RCInput
          class="information-container__input"
          :class="emptyFields['billing_reference'] === true ? 'missing-field' : ''"
          type="text"
          label="Billing reference"
          :floatLabel="true"
          v-model="load.billing_reference"
        />
      </div>
    </div>
    <template v-if="$store.getters['login/getTokenInfo'].magaya_guid">
      <div v-if="renderView === 'Desktop'" class="magaya-dropdown">
        <RCSelect
          class="magaya-dropdown__dropdown"
          :suggestions="magayaDropdownOptions"
          :floatLabel="false"
          v-model="magayaGroundShipment"
          @input="updateGroundShipment"
        />
      </div>
      <div v-if="renderView === 'Responsive'" class="magaya-dropdown magaya-dropdown--responsive">
        <div class="information-container__title"><span>Ground shipment:</span></div>
        <RCSelect
          class="magaya-dropdown__dropdown"
          :suggestions="magayaDropdownOptions"
          :floatLabel="false"
          v-model="magayaGroundShipment"
          @input="updateGroundShipment"
        />
      </div>
    </template>
  </div>
</template>

<script>
import RCSelect from "../../../../components/RCComponents/CustomRCSelect.vue";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";

export default {
  name: "LoadDetail",
  props: {
    chosenLoad: Object,
    renderView: String,
    persistedInformation: Object,
    screenWidth: Number,
  },
  components: {
    RCSelect,
    RCInput,
  },
  data() {
    return {
      load: {
        billing_reference: "",
        po_number: "",
      },
      emptyFields: {
        billing_reference: false,
        po_number: false,
      },
      magayaGroundShipment: "Ground shipment",
      magayaDropdownOptions: [
        { label: "Ground shipment", value: "Ground shipment" },
        { label: "Pickup order", value: "Pickup order" },
      ],
      loadId: null
    };
  },
  created() {
    this.loadId = this.$route.params.loadId;
  },
  watch: {
    persistedInformation: {
      handler() {
        this.load.billing_reference = this.persistedInformation.billing_reference;
        this.load.po_number = this.persistedInformation.po_number;
      },
      deep: true,
    },
  },
  methods: {
    updateData() {
      const payload = {
        billing_reference: this.load.billing_reference,
        po_number: this.load.po_number,
        mode: "loadInformation",
      };
      this.$emit("getReferences", payload);
    },
    async dataHandling(key) {
      if (this.load[key] && this.load[key] !== this.chosenLoad[key]) {
        await this.$store.dispatch("load/updateLoad", {
          id: this.loadId,
          body: { [key]: this.load[key] },
        });
      }
    },
    validateEqualInfo() {
      let fieldsAreSame = true;
      Object.keys(this.load).forEach((key) => {
        if (this.load[key] !== this.chosenLoad[key]) {
          this.$store.commit("load/modifyChosenLoadSpecificData", { key, value: this.load[key] });
          fieldsAreSame = false;
        }
      });
      return fieldsAreSame;
    },
    updateLoadInformation() {
      if (!this.validateEqualInfo()) {
        return this.$store.dispatch("load/updateLoad", {
          id: this.loadId,
          body: this.load,
        });
      }
      return false;
    },
    updateGroundShipment() {
      this.$store.commit(
        "load/setGroundShipment",
        this.magayaGroundShipment.toLowerCase() === "ground shipment"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.load-detail {
  width: 90%;
  margin: auto;
  text-align: start;
  &__title {
    color: $color-border-container;
    @include font-standart-text;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
  }
  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin: auto;
    margin-bottom: 10px;
  }
}
.information-container {
  font-size: 13px;
  display: flex;
  justify-content: inherit;
  align-items: center;
  font-size: 1rem;
  width: 40%;
  &__title {
    display: inline-block;
    padding: 0px 15px;
    width: 280px;
    span {
      font-size: 14px;
      font-weight: normal;
      font-weight: bold;
      color: $color-primary-company;
    }
  }
  &--reference {
    margin-left: 30px;
    @media (max-width: 800px) {
      margin-left: 0px;
    }
  }
  &__input {
    width: 100%;
    margin-top: 10px;
  }
}

.magaya-dropdown {
  margin-top: 12px;
  @media (min-width: 799px) {
    width: 40%;
  }
  @media (max-width: 799px) {
    max-width: 418px;
  }
  &__dropdown {
    height: 100%;
    width: 100%;
    border-radius: 14px;
  }
}
@media (max-width: 799px) {
  .magaya-dropdown {
    &--responsive {
      display: flex;
      align-items: baseline;
      width: 100%;
    }
  }
  .information-container {
    width: 100%;
    margin: 15px auto;
    &__input {
      max-width: 250px;
      justify-content: space-between;
      font-size: 14px;
      height: 30px;
      border-radius: 10px;
    }
    &__title {
      padding: 0px 15px 0px 0px;
    }
  }
}

::v-deep {
  @media (max-width: 799px) {
    .rc-input-container {
      height: 30px;
    }
    .rc-floating-label {
      font-size: 14px;
    }
    .rc-floating-centered {
      font-size: 14px;
    }
    .rc-label-active {
      font-size: 11px;
      transform: translateY(-16px);
    }
    .dropdown {
      box-shadow: 5px 4px 5px -2px #c4c4c4;
      margin-left: 5px;
      font-weight: 500;
      width: 120px;
    }
    .dropdown-container {
      border: 1px solid $color-border-container;
      height: 30px;
      z-index: 10;
      border-radius: 10px;
    }
    .dropdown-container__selected-option {
      max-height: 28px;
      font-size: 14px;
      border-radius: 10px;
    }
    .dropdown-container__option {
      font-size: 14px;
    }
    .information-container__input {
      max-width: none;
    }
    & .rc-select-container {
      height: 30px;
      width: 60% !important;
      margin-bottom: 20px;
    }
  }
}
</style>
