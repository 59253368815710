<template>
  <div class="table-row-content">
    <div class="table-row-list" v-for="(line, index) in lineItems" :key="index">
      <div v-if="isReefer">
        <TemperatureComponent :isLineItemFTL="false" />
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
        class="table-row-list__item--description"
      >
        <input
          @blur="dataHandling(line.description, 'description', index)"
          class="input-information input-information--description"
          type="text"
          v-model="line.description"
          :placeholder="line.description"
        />
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
        class="table-row-list__item--description nmfc-input"
      >
        <input
          @blur="dataHandling(line.nmfc_number, 'nmfc_class', index)"
          class="input-information input-information--description"
          type="text"
          v-model="line.nmfc_number"
          :placeholder="line.nmfc_number"
          :disabled="!line.nmfcFieldEnabled"
        />
        <span class="popover-container">
          <button class="tooltip-icon" type="button" :id="'lineitem-popover-' + index">
            <i class="ion ion-information-circle-outline"></i>
          </button>
          <b-popover
            ref="popover"
            :target="'lineitem-popover-' + index"
            triggers="hover"
            placement="bottom"
          >
            <span>{{ line.nmfcPopover }}</span>
          </b-popover>
        </span>
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
      >
        {{ line.handling_unit_count }} {{ line.handling_units }}
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
      >
        <p>
          {{ ceilDecimals(line.dimensions.length) }} x {{ ceilDecimals(line.dimensions.width) }} x
          {{ ceilDecimals(line.dimensions.height) }}
          ({{ line.dimensions.dimension_units }})
        </p>
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
        class="table-row-list__item--weight"
      >
        {{ line.weight }} ({{ line.weight_units }})
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
        class="table-row-list__item--nmfc_class"
      >
        {{ line.nmfc_class }}
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
      >
        <input
          @blur="dataHandling(line.packaging_unit_count, 'packaging_unit_count', index)"
          class="input-information input-information--description input-information--pu-count"
          min="0"
          type="number"
          v-model="line.packaging_unit_count"
          :placeholder="line.packaging_unit_count"
        />
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
      >
        <div v-if="lineItemsPickList">
          <RCSelect
            label="Choose an option"
            v-if="lineItemsPickList.packaging_units"
            class="packaging-units-dropdown"
            :floatLabel="false"
            :suggestions="lineItemsPickList.packaging_units"
            v-model="line.packaging_units"
            @change="dataHandling(line.packaging_units, 'packaging_units', index)"
          />
        </div>
      </div>
      <div
        :class="
          line.hazardous_materials ? 'table-row-list__item-more' : 'table-row-list__item-lees'
        "
        v-if="isHazmat"
        class="table-row-list__item--hazardous_materials"
      >
        <HazmatCheckbox
          :itemIsHazmat="line.hazardous_materials"
          :hazmatIndex="index"
          :hazmat="hazmatInformation[index]"
          @removeHazmatInformation="
            removeHazmatInformation($event, index), updateHazmatInformation($event, index)
          "
          @addHazmatInformation="
            addHazmatInformation($event, index), updateHazmatInformation($event, index)
          "
          @isRequiredHazmat="requiredHazmat"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RCSelect from "../../../../../components/RCComponents/CustomRCSelect.vue";
import HazmatCheckbox from "./hazmat/HazmatCheckbox.vue";
import TenderLineItems from "../../../../../Extend/TenderLineItems";
import TemperatureComponent from "./TemperatureComponent.vue";

export default {
  name: "TableRowContent",
  props: {
    lineItems: Array,
    hazmatInformation: Array,
    isHazmat: Boolean,
    completeData: Array,
    isReefer: Boolean,
  },
  mixins: [TenderLineItems],
  components: {
    HazmatCheckbox,
    RCSelect,
    TemperatureComponent,
  },
  data() {
    return {};
  },
  async created() {
    if (!this.lineItemsPickList) {
      await this.$store.dispatch("load/lineItems/getLineItemPickList");
    }
  },
  watch: {
    lineItemsPickList: {
      deep: true,
      immediate: true,
      handler() {
        if (this.lineItemsPickList) {
          this.$emit("handlingLoad", false);
        }
      },
    },
  },
  computed: {
    lineItemsPickList: {
      get() {
        return this.$store.getters["load/lineItems/getLineItemsPicklists"];
      },
      set(value) {
        this.lineItemsPickList = value;
      },
    },
  },
  methods: {
    async dataHandling(value, key, index) {
      if (!value) {
        return;
      }
      this.$store.commit("load/tender/setRequestInProcess", true);
      if (key === "description") {
        this.lineItems[index].description = value;
      } else if (key === "nmfc_class") {
        this.lineItems[index].nmfc_number = value;
      }
      delete this.lineItems[index].density;
      delete this.lineItems[index].linear_feet;
      const payload = {
        id: this.$route.params.loadId,
        lineItemId: this.lineItems[index].line_item_id,
        body: this.lineItems[index],
      };
      if (this.$options.filters.checkPermission("patch:line-item")) {
        await this.$store.dispatch("load/lineItems/updatedLineItem", payload);
      }
      this.$store.commit("load/tender/setRequestInProcess", false);
    },
    addHazmatInformation(data, index) {
      this.isHazmatInformation = data;
      this.lineItems[index].hazardous_materials = true;
      this.lineItems[index] = { ...this.lineItems[index], ...data };
      this.lineItems[index].hazmat_contact = process.env.VUE_APP_HAZMAT_ID_DEV;
      this.updateVuexLineItems(
        {
          ...data,
          hazardous_materials: true,
          nmfcFieldEnabled: this.lineItems[index].nmfcFieldEnabled,
          nmfcPopover: this.lineItems[index].nmfcPopover,
        },
        index
      );
      this.$emit("searchItemsWithHazmat", this.lineItems);
    },
    removeHazmatInformation(data, index) {
      this.lineItems[index].hazardous_materials = false;
      this.lineItems[index] = { ...this.lineItems[index], ...data };
      this.lineItems[index].hazmat_contact = "";
      this.updateVuexLineItems(
        {
          ...data,
          hazardous_materials: false,
          nmfcFieldEnabled: this.lineItems[index].nmfcFieldEnabled,
          nmfcPopover: this.lineItems[index].nmfcPopover,
        },
        index
      );
      this.$emit("searchItemsWithHazmat", this.lineItems);
    },
    requiredHazmat(data) {
      this.$emit("isRequiredHazmat", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-row-content {
  padding: 0px 15px;
}
.table-row-list {
  display: flex;
  align-items: center;
  height: 60px;
  color: $color-primary-company;
  font-weight: 600;
  border-bottom: 1px solid $color-border-container;
  padding: 10px 0px;
  border-top: none;
  &__item {
    // width: 20.58% !important;
    &-more {
      @include hide-input-number-arrows;
      width: 14.58%;
    }
    &-lees {
      @include hide-input-number-arrows;
      width: 14.58%;
    }
    &--description {
      padding: 10px;
    }
    &--weight {
      width: 7% !important;
    }
    &--nmfc_class {
      width: 7% !important;
    }
    &--hazardous_materials {
      width: 10%;
    }
  }
  p {
    margin: 0 !important;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  @include font-small-standart-text;
  border-radius: 10px;
  &--description {
    border: 1px solid $color-border-container;
    text-align: center;
    width: 100%;
  }
  &--pu-count {
    width: 95%;
  }
}

.nmfc-input {
  position: relative;
  width: 12% !important;
}

.popover-container {
  position: absolute;
  top: 16px;
  right: 14px;
}

button {
  border: none;
  background: none;
  padding: 0;
  color: $color-primary-company;
  cursor: initial !important;
}

::v-deep .packaging-units-dropdown {
  width: 90%;
  margin-left: 5px;
  font-weight: normal;
  & .rc-select-container {
    height: 33px;
  }
  & .rc-floating-label,
  .rc-select,
  .rc-suggestions-list {
    font-size: 14px;
  }
  & .rc-suggestions {
    top: 30px;
  }
}
::v-deep {
  .rc-input-container {
    height: 33px;
  }
  .rc-select-container {
    height: 33px;
  }
  .rc-select,
  .rc-suggestions-list__item {
    font-weight: normal;
    font-size: 14px;
  }
}
</style>
