<template>
  <div class="temperature">
    <ul class="temperature-list">
      <li class="temperature__number">
        <RCInput
          type="number"
          v-model="temperatureValue"
          class="temperature-value"
          :class="{
            'missing-field': emptyFields['temperature'],
            temperature__reefer: isLineItemFTL,
            temperature__input: !isLineItemFTL,
          }"
          :label="isLineItemFTL ? $t('accountTable.temperature') : ''"
          :floatLabel="isLineItemFTL ? true : false"
          :requiredField="false"
        />
      </li>
      <li class="temperature__selector">
        <RCSelect
          class="temperature-selector"
          :suggestions="temperatureSuggestions"
          :label="''"
          v-model="temperatureUnitMeasurement"
          :key="keyRenderUnits"
          @change="convertTemperatureValue(temperatureValue)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RCSelect from "../../../../../components/RCComponents/CustomRCSelect.vue";
import RCInput from "../../../../../components/RCComponents/CustomRCInput.vue";

export default {
  name: "TemperatureComponent",
  props: {
    isLineItemFTL: Boolean,
  },
  components: {
    RCSelect,
    RCInput,
  },
  data() {
    return {
      temperatureSuggestions: [
        { label: "F", value: "F" },
        { label: "C", value: "C" },
      ],
      selectedCarrier: {},
      sourceCarrier: "",
      emptyFields: {
        temperature: false,
      },
      keyRenderUnits: 0,
    };
  },
  computed: {
    ...mapGetters({
      carriersList: "carriers/getCarriers",
      triggerTemperatureValidation: "load/lineItems/getTriggerValidations",
      fahrenheitTemperature: "load/lineItems/getLineItemsTemperatureToSend",
    }),
    temperatureValue: {
      get() {
        return this.$store.state.load.lineItems.temperatureValue;
      },
      set(value) {
        this.$store.commit("load/lineItems/setLineItemsTemperature", value);
        this.convertTemperatureValue(parseInt(value, 10));
      },
    },
    temperatureUnitMeasurement: {
      get() {
        return this.$store.state.load.lineItems.temperatureUnitMeasurement;
      },
      set(value) {
        this.$store.commit("load/lineItems/setTemperatureUnitMeasurement", value);
      },
    },
  },
  watch: {
    temperatureUnitMeasurement: {
      handler() {
        this.keyRenderUnits += 1;
      },
    },
    carriersList: {
      immediate: true,
      handler() {
        this.selectedCarrierMethod();
      },
    },
    triggerTemperatureValidation: {
      handler() {
        this.validateFields(this.sourceCarrier, this.fahrenheitTemperature);
      },
    },
  },
  created() {
    this.selectedCarrierMethod();
  },
  methods: {
    convertTemperatureValue(temperatureValue) {
      let fahrenheitTemperatureValue = 0;
      if (this.temperatureUnitMeasurement === "C") {
        fahrenheitTemperatureValue = temperatureValue * (9 / 5) + 32;
        fahrenheitTemperatureValue = Math.round(fahrenheitTemperatureValue * 100) / 100;
      } else {
        fahrenheitTemperatureValue = parseInt(temperatureValue, 10);
      }
      this.$store.commit("load/lineItems/setTemperatureToSendValue", fahrenheitTemperatureValue);
    },
    selectedCarrierMethod() {
      this.selectedCarrier = this.carriersList.find((carrier) => {
        const { _id } = carrier;
        return _id === this.$route.params.carrierId;
      });
      if (this.selectedCarrier) {
        this.sourceCarrier = this.selectedCarrier.source;
      }
    },
    validateFields(source, value) {
      let validation = true;
      const temperature = parseInt(value, 10);
      if (source === "REEFER") {
        if (!Number.isNaN(temperature) && temperature >= -20 && temperature <= 70) {
          this.emptyFields.temperature = false;
          validation = true;
        } else {
          this.emptyFields.temperature = true;
          validation = false;
        }
      } else if (temperature) {
        if (temperature >= -20 && temperature <= 70) {
          this.emptyFields.temperature = false;
          validation = true;
        } else {
          this.emptyFields.temperature = true;
          validation = false;
        }
      }
      this.$store.commit("load/lineItems/setValidationTemperature", validation);
    },
  },
};
</script>

<style lang="scss" scoped>
.temperature {
  display: flex;
  &-list {
    @include remove-custom-component-styles;
    position: relative;
    display: flex;
    // flex-basis: 20%;
    list-style-type: none;
  }
  &__number {
    @include hide-input-number-arrows;
    // width: 50%;
  }
  &__selector {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
    width: 50%;
  }
  &__input {
    width: 85px;
    height: 10px;
  }
  &__reefer {
    width: 120px;
  }
}

::v-deep .missing-field .rc-input-container {
  background-color: $missing-fields;
}

::v-deep .temperature-value .rc-input-container {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
::v-deep .temperature-selector .rc-select-container {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0px;
}
</style>
