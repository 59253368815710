<template>
  <div class="lineitems">
    <div v-if="isHazmat && !isAnyItemWithHazmat">
      <HazmatErrorNotification />
    </div>
    <div class="lineitems-spinner" v-if="loading">
      <b-spinner small></b-spinner>
    </div>
    <div v-else :key="remountLineItems">
      <section
        class="lineitem-container"
        :id="'line-item' + index"
        v-for="(option, index) in selectedLineItems"
        :key="index"
      >
        <b-overlay
          :show="option.isLoading"
          spinner-small
          rounded="sm"
          class="d-flex flex-column align-items-center"
          style="margin: 7px auto"
        >
          <header class="lineitem-container__title">
            <span>{{ $t("allOptions.line") }} {{ index + 1 }}</span>
            <button
              class="remove-button"
              v-if="showDeleteButton && $route.name.toLowerCase() !== 'loadsdetails'"
              type="button"
              @click="deleteLine(index)"
            >
              <i class="ion ion-close-circle-outline remove-button__icon" title="Remove Line"></i>
            </button>
          </header>
          <div class="lineitem-row lineitem-ftl" :class="!isHazmat ? 'no-hazmat' : 'is-hazmat'">
            <div class="first-section">
              <div v-if="selectedLineItems[0].quoteMode === 'reefer'" class="temperature">
                <TemperatureComponent :isLineItemFTL="true" />
              </div>
              <div class="hucount lineitem-inputs">
                <RCInput
                  class="hucount__input"
                  :class="
                    error[index] && error[index].handling_unit_count === true ? 'missing-field' : ''
                  "
                  :label="$t('allOptions.handlingUnitsCount')"
                  :positiveNumbers="true"
                  :floatLabel="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.handling_unit_count"
                  @blur="dataHandling(index, 'handling_unit_count')"
                />
                <RCInput
                  class="hucount__dropdown"
                  :floatLabel="true"
                  :class="
                    error[index] && error[index].handling_units === true ? 'missing-field' : ''
                  "
                  :label="$t('allOptions.handlingUnits')"
                  :type="'text'"
                  :requiredField="true"
                  :suggestions="handlingUnitsList"
                  :forceSelection="true"
                  v-model="option.handling_units"
                  @input="dataHandling(index, 'handling_units')"
                />
              </div>
              <div class="pkgcount lineitem-inputs">
                <RCInput
                  class="pkgcount__input"
                  :class="
                    error[index] && error[index].packaging_unit_count === true
                      ? 'missing-field'
                      : ''
                  "
                  :label="$t('allOptions.palletsCount')"
                  :positiveNumbers="true"
                  :requiredField="false"
                  :floatLabel="true"
                  type="number"
                  v-model="option.packaging_unit_count"
                  @blur="dataHandling(index, 'packaging_unit_count')"
                />
                <RCInput
                  class="pkgcount__dropdown"
                  :floatLabel="true"
                  :class="
                    error[index] && error[index].packaging_units === true ? 'missing-field' : ''
                  "
                  :label="$t('allOptions.palletsCountFull')"
                  :type="'text'"
                  :suggestions="handlingUnitsList"
                  v-model="option.packaging_units"
                  @input="dataHandling(index, 'packaging_units')"
                />
              </div>
            </div>
            <ul class="weight lineitem-inputs">
              <li
                class="weight__input"
                :class="error[index] && error[index].weight === true ? 'missing-field' : ''"
              >
                <RCInput
                  :label="$t('allOptions.weight')"
                  :positiveNumbers="true"
                  :floatLabel="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_weight"
                  @blur="dataHandling(index, 'weight')"
                />
              </li>
              <li class="weight__dropdown">
                <RCSelect
                  :id="index"
                  :suggestions="picklist.weight_units"
                  :label="$t('allOptions.weightUnits')"
                  v-model="option.user_weight_units"
                  @change="dataHandling(index, 'weight_units')"
                />
              </li>
            </ul>
            <ul class="dimensions lineitem-inputs">
              <li
                class="dimensions__input"
                :class="error[index] && error[index].length === true ? 'missing-field' : ''"
              >
                <RCInput
                  :label="$t('allOptions.length')"
                  :positiveNumbers="true"
                  :floatLabel="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_length"
                  @blur="dataHandling(index, 'length')"
                />
              </li>
              <li
                class="dimensions__input"
                :class="error[index] && error[index].width === true ? 'missing-field' : ''"
              >
                <RCInput
                  :label="$t('allOptions.width')"
                  :positiveNumbers="true"
                  :floatLabel="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_width"
                  @blur="dataHandling(index, 'width')"
                />
              </li>
              <li
                class="dimensions__input"
                :class="error[index] && error[index].height === true ? 'missing-field' : ''"
              >
                <RCInput
                  :label="$t('allOptions.height')"
                  :positiveNumbers="true"
                  :floatLabel="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_height"
                  @blur="dataHandling(index, 'height')"
                />
              </li>
              <li class="dimensions__dropdown">
                <RCSelect
                  :id="index"
                  :suggestions="picklist.dimension_units"
                  :label="$t('allOptions.dimensionsUnits')"
                  v-model="option.user_dimension_units"
                  @change="dataHandling(index, 'dimension_units')"
                />
              </li>
            </ul>
            <div class="commodity lineitem-inputs">
              <RCInput
                class="commodity__input"
                :class="error[index] && error[index].description === true ? 'missing-field' : ''"
                :label="$t('allOptions.commodity')"
                :type="'text'"
                :floatLabel="true"
                :requiredField="true"
                v-model="option.description"
                @blur="dataHandling(index, 'description')"
                :suggestions="commoditySuggestions"
              />
            </div>
            <div class="hazmat lineitem-inputs" v-if="isHazmat">
              <Hazmat
                :itemIsHazmat="option.hazardous_materials"
                :hazmatIndex="index"
                :hazmat="hazmatInformation[index]"
                @removeHazmatInformation="removeHazmatInformation($event, index)"
                @addHazmatInformation="addHazmatInformation($event, index)"
              />
            </div>
          </div>
        </b-overlay>
      </section>
    </div>
    <div class="lineitems__content-buttons">
      <button class="button-add" @click="addLine()">
        <b-icon icon="plus" font-scale="2.5"></b-icon>
        {{ $t("allOptions.addLine") }}
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable consistent-return */
/* eslint-disable implicit-arrow-linebreak */
import { mapGetters } from "vuex";
import { diff } from "json-diff";
import { convertWeightMeasurements, convertLengthMeasurements } from "@/utils/measurementConverter";
import RCInput from "../../../../../components/RCComponents/CustomRCInput.vue";
import RCSelect from "../../../../../components/RCComponents/CustomRCSelect.vue";
import HazmatErrorNotification from "./hazmat/HazmatErrorNotification.vue";
import LineItemTenderFTLSchema from "../../../../../schemas/LineItemTenderFTLSchema";
import Hazmat from "./hazmat/HazmatCheckbox.vue";
import modes from "../../../modes";
import TemperatureComponent from "./TemperatureComponent.vue";

export default {
  name: "LineItemsFTL",
  props: {
    lineItems: Array,
    isHazmat: Boolean,
    quoteMode: String,
  },
  components: {
    RCInput,
    RCSelect,
    Hazmat,
    HazmatErrorNotification,
    TemperatureComponent,
  },
  data() {
    return {
      loading: false,
      remountLineItems: 0,
      selectedLineItems: [],
      showDeleteButton: false,
      error: [],
      picklist: {},
      commoditySuggestions: [],
      // This array references the line items that were created in the last post requests
      lastCreatedLineItems: [],
      // Hazmat
      isAnyItemWithHazmat: false,
      hazmatInformation: [],
      totalWeight: 0,
      limitsLineItemsError: []
    };
  },
  async created() {
    this.loading = true;
    await this.getPickListItem();
    this.commoditySuggestions = JSON.parse(localStorage.getItem("commodities")) || [];
    this.selectedLineItems = JSON.parse(JSON.stringify(this.lineItems));
    this.$emit("emitLineItems", this.selectedLineItems);
    this.loading = false;
  },
  computed: {
    ...mapGetters({ chosenLoad: "load/getChosenLoad" }),
    handlingUnitsList() {
      return this.picklist.handling_units.map((item) => item.value);
    },
  },
  watch: {
    selectedLineItems: {
      deep: true,
      immediate: true,
      handler() {
        this.showDeleteButton = this.selectedLineItems.length > 1;
        this.calculateTotalWeight();
        // Hazmat
        this.getHazmatInformation();
        this.findItemWithHazmatInfo();
      },
    },
  },
  methods: {
    // validate the requiered lineitems ftl
    async validateFormfields() {
      const lineItems = [...this.selectedLineItems];
      let validateForm = true;
      this.error = [];
      this.limitsLineItemsError = [];
      for (let i = 0; i < lineItems.length; i += 1) {
        const itemError = {};
        try {
          // eslint-disable-next-line no-await-in-loop
          await LineItemTenderFTLSchema.validate(lineItems[i], {
            abortEarly: false,
          });
        } catch (error) {
          validateForm = false;
          error.inner.forEach((element) => {
            itemError[element.path] = true;
            if (
              element.type === "weight-limit" ||
              element.type === "height-limit" ||
              element.type === "width-limit" ||
              element.type === "length-limit"
            ) {
              const errrorMessage = element.message;
              this.limitsLineItemsError.push(errrorMessage);
            }
          });
          this.swal({
            title: "Missing fields",
            text: "Please, try again.",
            icon: "error",
            dangerMode: true,
          });
        }
        this.error.push(itemError);
      }
      return validateForm;
    },
    validateFTLLimits() {
      if (this.totalWeight > this.chosenLoad.total_weight) {
        return false;
      }
      return true;
    },
    calculateTotalWeight() {
      this.totalWeight = 0;
      this.selectedLineItems.forEach((el) => {
        this.totalWeight += parseInt(el.weight, 10);
      });
    },
    returnLineItemsMessageError() {
      return this.limitsLineItemsError;
    },
    notifyLengthLimit() {
      this.swal({
        title: `Length limit exceeded`,
        text: "The length limit has been exceeded in a item.",
        icon: "error",
        dangerMode: true,
      });
    },
    async dataHandling(index, key) {
      const dimensions = ["length", "width", "height", "dimension_units"];
      if (key.match("weight")) {
        this.weightConverter(index);
      } else if (dimensions.includes(key)) {
        this.dimensionConverter(index);
      }

      this.$emit("emitLineItems", this.selectedLineItems);
    },
    dimensionConverter(index) {
      this.selectedLineItems[index].length = parseFloat(
        convertLengthMeasurements(
          this.selectedLineItems[index].user_dimension_units,
          this.selectedLineItems[index].user_length
        ),
        10
      ).toFixed(2);
      this.selectedLineItems[index].width = parseFloat(
        convertLengthMeasurements(
          this.selectedLineItems[index].user_dimension_units,
          this.selectedLineItems[index].user_width
        ),
        10
      ).toFixed(2);
      this.selectedLineItems[index].height = parseFloat(
        convertLengthMeasurements(
          this.selectedLineItems[index].user_dimension_units,
          this.selectedLineItems[index].user_height
        ),
        10
      ).toFixed(2);
    },
    weightConverter(index) {
      this.selectedLineItems[index].weight = parseFloat(
        convertWeightMeasurements(
          this.selectedLineItems[index].user_weight_units,
          this.selectedLineItems[index].user_weight
        ),
        10
      ).toFixed(2);
    },
    addLine() {
      const newLine = {
        name: `Item ${this.selectedLineItems.length + 1}`,
        handling_units: "Pallets",
        dimension_units: "in",
        packaging_unit_count: null,
        packaging_units: null,
        weight_units: "lbs",
        nmfc_class: 0,
        height: null,
        length: null,
        width: null,
        weight: null,
        user_height: null,
        user_length: null,
        user_width: null,
        user_weight: null,
        user_dimension_units: "in",
        user_weight_units: "lbs",
        pickup_stop: "pickup",
        delivery_stop: "delivery",
        linear_feet: 0.1,
        description: null,
        handling_unit_count: null,
        isNewLineItem: true,
        hazardous_materials: this.isHazmat,
        quoteMode: this.quoteMode,
      };
      this.selectedLineItems.push(newLine);
      this.remountLineItems += 1;
      this.$emit("emitLineItems", this.selectedLineItems);
    },
    async deleteLine(index) {
      this.selectedLineItems.splice(index, 1);
      this.$emit("emitLineItems", this.selectedLineItems);
      this.remountLineItems += 1;
    },
    async getPickListItem() {
      if (this.$options.filters.checkPermission("get:line-item-picklists")) {
        const response = await this.$store.dispatch("load/lineItems/getLineItemPickList");
        Object.keys(response).forEach((key) => {
          this.picklist[key] = response[key].map((el) => ({ label: el.label, value: el.value }));
          if (key === "weight_units") {
            this.picklist.weight_units.push(
              { label: "kgs", value: "kgs" },
              { label: "ton", value: "ton" }
            );
          }
          if (key === "dimension_units") {
            this.picklist.dimension_units.push(
              { label: "cms", value: "cms" },
              { label: "mts", value: "mts" }
            );
          }
        });
      }
    },
    // List of requests for items to be updated or created (tender view)
    updateLoadLineItems() {
      if (
        this.chosenLoad.mode_id === modes.ftl.modeId ||
        this.chosenLoad.mode_id === modes.reefer.modeId
      ) {
        const createLineItems = this.dispatchLineItemsRequests(
          "createLineItem",
          this.getItemsToCreate(this.chosenLoad, this.selectedLineItems)
        );
        const updateLineItems = this.dispatchLineItemsRequests(
          "updatedLineItem",
          this.getItemsToUpdate(this.chosenLoad, this.selectedLineItems)
        );
        const deleteLineItems = this.dispatchLineItemsRequests(
          "deleteLineItem",
          this.getItemsToDelete(this.chosenLoad, this.selectedLineItems)
        );

        const listRequest = [createLineItems, updateLineItems, deleteLineItems];
        Promise.all(listRequest[0]).then((response) => {
          this.assignDbIdToCreatedLineItems(response);
        });
        return listRequest.flat();
      }
      return null;
    },
    dispatchLineItemsRequests(action, items) {
      const requestList = [];
      items.forEach((item) => {
        const { loadId } = this.$route.params;
        let payload = {};
        if (action === "createLineItem") {
          item.load_id = this.selectedLineItems[0].load_id;
          item.delivery_stop = this.chosenLoad.stops.find((stop) => stop.is_dropoff).id;
          item.pickup_stop = this.chosenLoad.stops.find((stop) => stop.is_pickup).id;
          payload = {
            id: loadId,
            body: item,
          };
          this.lastCreatedLineItems.push(item);
        }
        if (action === "updatedLineItem") {
          this.removeUnusedProperties(item);
          payload = {
            id: loadId,
            lineItemId: item.line_item_id,
            body: item,
          };
        }
        if (action === "deleteLineItem") {
          payload = {
            load_id: loadId,
            id: item.line_item_id,
          };
        }
        const request = this.$store.dispatch(`load/lineItems/${action}`, payload);
        requestList.push(request);
      });
      return requestList;
    },
    assignDbIdToCreatedLineItems(lineItemsIds) {
      this.lastCreatedLineItems.forEach((item, index) => {
        item.line_item_id = lineItemsIds[index].line_item_id;
      });
      this.lastCreatedLineItems = [];
    },
    getItemsToCreate(chosenLoad, selectedLineItems) {
      const itemsToCreate = selectedLineItems
        .filter(
          (item) =>
            !chosenLoad.line_items.some(
              (chosenItem) => chosenItem.line_item_id === item.line_item_id
            )
        )
        .map((item) => item);
      return itemsToCreate;
    },
    getItemsToUpdate(chosenLoad, selectedLineItems) {
      /* eslint-disable */
      const loadLineItems = chosenLoad.line_items;
      const componentItemsInDb = selectedLineItems.filter((item) => "line_item_id" in item);
      const itemsToUpdate = componentItemsInDb.filter((componentItem) => {
        const foundItem = loadLineItems.find(
          (item) => componentItem.line_item_id === item.line_item_id
        );
        if (diff(componentItem, foundItem)) {
          return diff(componentItem, foundItem);
        }
      });
      return itemsToUpdate;
    },
    getItemsToDelete(chosenLoad, selectedLineItems) {
      const itemsToDelete = chosenLoad.line_items
        .filter(
          (item) =>
            !selectedLineItems.some(
              (selectedItem) => selectedItem.line_item_id === item.line_item_id
            )
        )
        .map((item) => item);
      return itemsToDelete;
    },
    removeUnusedProperties(item) {
      delete item.density;
      delete item.linear_feet;
      delete item.nmfc_number;
    },
    // Hazmat FTL line items logic
    getHazmatInformation() {
      this.hazmatInformation = this.lineItems.map((item) => {
        const hazmat = {
          hazmat_class_division: item.hazmat_class_division,
          hazmat_number_type: item.hazmat_number_type,
          hazmat_packaging_group: item.hazmat_packaging_group,
          hazmat_number: item.hazmat_number,
        };
        return hazmat;
      });
    },
    async addHazmatInformation(data, index) {
      this.selectedLineItems[index].hazardous_materials = true;
      this.hazmatInformation[index] = { ...data };
      if (this.selectedLineItems[index].line_item_id) {
        this.selectedLineItems[index] = { ...this.selectedLineItems[index], ...data };
        this.selectedLineItems[index].hazmat_contact = process.env.VUE_APP_HAZMAT_ID_DEV;
        this.searchItemsWithHazmat(this.selectedLineItems);
        await this.updateHazmatInformation(data, index);
        this.updateVuexLineItems(
          {
            ...data,
            hazardous_materials: true,
          },
          index
        );
        this.$emit("emitLineItems", this.selectedLineItems);
        this.remountLineItems += 1;
        return;
      }
    },
    async removeHazmatInformation(data, index) {
      this.hazmatInformation[index] = {
        hazmat_class_division: "",
        hazmat_number_type: "",
        hazmat_packaging_group: "",
        hazmat_number: "",
      };
      if (this.selectedLineItems[index].line_item_id) {
        this.selectedLineItems[index].hazardous_materials = false;
        this.selectedLineItems[index] = { ...this.selectedLineItems[index], ...data };
        this.selectedLineItems[index].hazmat_contact = "";
        this.searchItemsWithHazmat(this.selectedLineItems);
        await this.updateHazmatInformation(data, index);
        this.updateVuexLineItems(
          {
            ...data,
            hazardous_materials: false,
          },
          index
        );
        this.$emit("emitLineItems", this.selectedLineItems);
        this.remountLineItems += 1;
        return;
      }
    },
    searchItemsWithHazmat(data) {
      if (data) {
        this.selectedLineItems = [...data];
      }
      this.isAnyItemWithHazmat = this.lineItems.some((item) => item.hazardous_materials);
      return this.isAnyItemWithHazmat;
    },
    async updateHazmatInformation(data, index) {
      if (this.$options.filters.checkPermission("patch:line-item")) {
        this.$store.commit("load/tender/setRequestInProcess", true);
        const payload = {
          id: this.$route.params.loadId,
          lineItemId: this.selectedLineItems[index].line_item_id,
          body: {
            hazardous_materials: this.selectedLineItems[index].hazardous_materials,
          },
        };
        Object.keys(data).forEach((key) => {
          payload.body[key] = data[key];
        });
        await this.$store.dispatch("load/lineItems/updatedLineItem", payload);
        this.$store.commit("load/tender/setRequestInProcess", false);
      }
    },
    findItemWithHazmatInfo(isHazmat) {
      if (typeof isHazmat === "undefined" && !isHazmat) {
        return false;
      }
      const hazmatVariables = [
        "hazmat_class_division",
        "hazmat_number_type",
        "hazmat_packaging_group",
        "hazmat_number",
      ];
      let infoWasFilled = false;
      for (let i = 0; i < this.selectedLineItems.length; i += 1) {
        for (let j = 0; j < hazmatVariables.length; j += 1) {
          if (this.selectedLineItems[i][hazmatVariables[j]]) {
            infoWasFilled = true;
            break;
          }
        }
        if (infoWasFilled) {
          break;
        }
      }
      this.isAnyItemWithHazmat = infoWasFilled;
      return infoWasFilled;
    },
    // Its executed when dispatch tender the load
    findDefaultHazmatItem() {
      const defaulHazmatItem = this.selectedLineItems.find((item) => {
        let foundItem = null;
        if (item.hazardous_materials && !this.checkIfHazmatInfoWasFilled(item)) {
          foundItem = item;
        }
        return foundItem;
      });
      if (defaulHazmatItem) {
        const payload = {
          id: this.$route.params.loadId,
          lineItemId: defaulHazmatItem.line_item_id,
          body: {
            hazardous_materials: false,
          },
        };
        return this.$store.dispatch("load/lineItems/updatedLineItem", payload);
      }
      return null;
    },
    checkIfHazmatInfoWasFilled(item) {
      const hazmatVariables = [
        "hazmat_class_division",
        "hazmat_number_type",
        "hazmat_packaging_group",
        "hazmat_number",
      ];
      let hazmatInfoFilled = true;
      hazmatVariables.forEach((variable) => {
        if (!item[variable]) {
          hazmatInfoFilled = false;
        }
      });
      return hazmatInfoFilled;
    },
    updateVuexLineItems(data, index) {
      const lineItemsCopy = JSON.parse(JSON.stringify(this.selectedLineItems));
      lineItemsCopy[index] = { ...lineItemsCopy[index], ...data };
      this.$store.commit("load/modifyChosenLoadSpecificData", {
        key: "line_items",
        value: lineItemsCopy,
      });
    },
    saveSelectedLineItemsInVuex() {
      const selectedLineItems = JSON.parse(JSON.stringify(this.selectedLineItems));
      this.$store.commit("load/modifyChosenLoadSpecificData", {
        key: "line_items",
        value: selectedLineItems,
      });
    },
    validateIfNmfcFieldwasFilled() {
      let itemNumber;
      let infoWasFilled = true;
      for (let i = 0; i < this.selectedLineItems.length; i += 1) {
        if (this.selectedLineItems[i].nmfcFieldEnabled && !this.selectedLineItems[i].nmfc_number) {
          infoWasFilled = false;
          itemNumber = i + 1;
          break;
        }
      }
      return { infoWasFilled, itemNumber };
    },
  },
};
</script>

<style lang="scss" scoped>
// Import responsive styles
@import "@/assets/scss/components/LineItemsFTL.scss";
@import "../../../../../assets/scss/components/RCComponents.scss";

.dropdown-container {
  border: 1px solid $color-border-container;
  height: 42px;
}
.lineitems-spinner {
  width: 100%;
  text-align: center;
}
.lineitem-container {
  width: 100%;
  margin-bottom: 5px;
  &__title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      margin: 0px;
      margin-left: 6px;
    }
  }
}
.lineitem-row {
  width: 100%;
  display: flex;
  margin: 20px 0px;
  position: relative;
}
.lineitem-row {
  ::v-deep {
    & .hucount {
      display: flex;
      flex-basis: 33%;
      &__dropdown {
        width: 75%;
        margin-left: 5px;
      }
      &__input {
        @include hide-input-number-arrows;
        width: 25%;
        height: 42px;
      }
    }
    & .pkgcount {
      display: flex;
      flex-basis: 55% !important;
      &__dropdown {
        width: 75%;
        margin-left: 5px;
      }
      &__input {
        @include hide-input-number-arrows;
        width: 25%;
        height: 42px;
      }
    }
    & .weight {
      @include remove-custom-component-styles;
      display: flex;
      flex-basis: 20%;
      list-style-type: none;
      margin: 0px 5px;
      &__input {
        @include hide-input-number-arrows;
        width: 50%;
      }
      & .rc-input-container,
      .rc-input-field {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      &__dropdown {
        & .rc-select-container {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: none;
        }
        width: 50%;
      }
    }
    & .dimensions {
      @include remove-custom-component-styles;
      display: flex;
      flex-basis: 45%;
      list-style-type: none;
      &__input {
        &:not(:first-child) {
          & .rc-input-container,
          .rc-input-field {
            @include hide-input-number-arrows;
            border-radius: 0px;
            border-right: none;
          }
        }
        & .rc-input-container:nth-of-type(1),
        .rc-input-field:nth-of-type(1) {
          @include hide-input-number-arrows;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          border-right: none;
        }
        width: 25%;
      }
      & .floatlabel-input {
        border: none;
      }
      &__dropdown {
        & .rc-select-container {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
        width: 25%;
      }
    }
    & .commodity {
      flex-basis: 43%;
      &__input {
        height: 42px;
      }
    }
  }
}
.no-hazmat {
  ::v-deep {
    & .hucount {
      flex-basis: 44.8%;
      &__dropdown {
        width: 50%;
      }
      &__input {
        width: 50%;
      }
    }
    & .pkgcount {
      flex-basis: 44.8%;
      &__dropdown {
        width: 50%;
      }
      &__input {
        width: 50%;
      }
    }
    & .weight {
      flex-basis: 25%;
    }
    & .dimensions {
      flex-basis: 45%;
    }
    & .commodity {
      flex-basis: 32%;
    }
  }
}
.is-hazmat {
  ::v-deep {
    & .hucount {
      flex-basis: 40%;
      &__dropdown {
        width: 70%;
      }
      &__input {
        width: 30%;
      }
    }
    & .pkgcount {
      flex-basis: 55%;
      &__dropdown {
        width: 70%;
      }
      &__input {
        width: 30%;
      }
    }
    & .weight {
      flex-basis: 25%;
    }
    & .dimensions {
      flex-basis: 35%;
    }
    & .commodity {
      flex-basis: 32%;
    }
  }
}

.lineitem-inputs {
  margin: 0px 5px;
  height: 42px;
  & li {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
.hazmat {
  display: flex;
}
///****Buttons styles****\\\\
.button-add {
  display: flex;
  align-items: center;
  color: $color-primary-company;
  border: none;
  padding: 0px;
  background: none;
}
.remove-button {
  right: 0%;
  top: 0%;
  @include go-back-button;
  padding: 0 10px 0 0;
  margin: 0;
  font-weight: bold;
  &__icon {
    font-size: 25px;
  }
}

.disabled {
  pointer-events: none;
}
@media (max-width: 1325px) {
  .lineitem-row {
    flex-wrap: wrap;
  }
  .no-hazmat {
    margin-bottom: 20px;
    ::v-deep {
      & .hucount {
        flex-basis: 47.9%;
      }
      & .weight {
        margin-top: 25px !important;
      }
      & .dimensions {
        margin-top: 25px !important;
        flex-basis: 40%;
      }
      & .pkgcount {
        flex-basis: 48.9%;
      }
      & .commodity {
        margin-top: 25px !important;
        flex-basis: 32%;
      }
    }
  }
}
@media (max-width: 720px) {
  ::v-deep {
    & .hucount {
      margin-top: 25px;
    }
  }
}
.first-section {
  display: flex;
  flex-direction: row;
  width: 100%;
}
</style>
