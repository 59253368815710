import DexieDb from "@/utils/db";

export default {
  methods: {
    createIndexedDB(dbName) {
      DexieDb.createDb({ dbName });
    },
    async checkLoadIdInIndexedDB(dbName, collection, id, data) {
      const collectionInformation = await DexieDb.getFromDb({
        dbName,
        collection,
        id,
      });
      if (!collectionInformation) {
        DexieDb.addToDb({
          collection,
          data: {
            loadId: id,
            items: { ...data },
          },
        });
        return;
      }
      this.retrieveInfoFromIndexedDBCollection(collectionInformation.items);
    },
    updateCollectionInIndexedDB(collection, id, data) {
      DexieDb.updateDb({
        collection,
        data: {
          loadId: id,
          items: { ...data },
        },
      });
    },
    deleteIndexedDBInfo(collection, id) {
      DexieDb.deleteFromDb({
        collection,
        id,
      });
    },
  },
};
