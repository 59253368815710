<template>
  <div class="hazmat-modal" v-if="hazmat">
    <b-overlay :show="loading" rounded="sm">
      <div class="hazmat-modal__content" :key="key">
        <div class="hazmat-modal__header">
          <div class="hazmat-modal__title">
            <h3>Hazmat Info</h3>
          </div>
          <div class="hazmat-modal__button">
            <button
              @click="$emit('input', hazmat), closeModal()"
              type="button"
              class="close-button"
            >
              <i class="ion ion-close-circle-outline close-button__icon"></i>
            </button>
          </div>
        </div>
        <hr />
        <div class="hazmat__content-inputs-container">
          <RCSelect
            :class="emptyFields['hazmat_number_type'] === true ? 'missing-field' : ''"
            :suggestions="hazmatNumberType"
            :floatLabel="true"
            :label="$t('allOptions.hazmatNumberType')"
            v-model="hazmat.hazmat_number_type"
          />
        </div>
        <div class="hazmat__content-inputs-container">
          <RCInput
            :class="emptyFields['hazmat_number'] === true ? 'missing-field' : ''"
            class="input-container"
            :label="$t('allOptions.hazmatNumber')"
            :type="'number'"
            @input="validateNumberDigits($event)"
            v-model="hazmat.hazmat_number"
          />
          <span v-show="emptyFields.hazmat_number" class="validation">
            *{{ $t("allOptions.validationNumberDigits") }}
          </span>
        </div>
        <div class="hazmat__content-inputs-container">
          <RCSelect
            :class="emptyFields['hazmat_class_division'] === true ? 'missing-field' : ''"
            :suggestions="hazmatClassDivision"
            :floatLabel="true"
            :label="$t('allOptions.hazmatClassDivision')"
            v-model="hazmat.hazmat_class_division"
          />
        </div>
        <div class="hazmat__content-inputs-container">
          <RCSelect
            :class="emptyFields['hazmat_packaging_group'] === true ? 'missing-field' : ''"
            :suggestions="hazmatPackagingGroup"
            :floatLabel="true"
            :label="$t('allOptions.hazmatPackagingGroup')"
            v-model="hazmat.hazmat_packaging_group"
          />
        </div>
        <button
          @click="saveHazmatInformation()"
          class="button-container"
          v-html="$t('stopsInfo.createStopInfo.save')"
        ></button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import RCInput from "../../../../../../components/RCComponents/CustomRCInput.vue";
import RCSelect from "../../../../../../components/RCComponents/CustomRCSelect.vue";

export default {
  name: "HazmatModal",
  components: {
    RCSelect,
    RCInput,
  },
  props: {
    hazmatData: Object,
  },
  data() {
    return {
      loading: false,
      hazmatClassDivision: [],
      hazmatNumberType: [],
      hazmatPackagingGroup: [],
      hazmat: {
        hazmat_class_division: null,
        hazmat_number_type: null,
        hazmat_packaging_group: null,
        hazmat_number: null,
        // hazmat_contact: Aqui va el id quemado
      },
      emptyFields: {
        hazmatClassDivision: false,
        hazmat_number_type: false,
        hazmat_packaging_group: false,
        hazmat_number: false,
        // hazmat_contact: Aqui va el id quemado | false
      },
      editMode: false,
      key: 0
    };
  },
  watch: {
    hazmat() {
      this.editMode = true;
    },
  },
  async created() {
    if (this.hazmatData) {
      this.hazmat = { ...this.hazmatData };
    }
    await this.$store.dispatch("load/lineItems/getLineItemPickList");
    // eslint-disable-next-line max-len
    this.hazmatClassDivision = await this.$store.getters["load/lineItems/getLineItemsPicklists"]
      .hazmat_class_division;
    // eslint-disable-next-line max-len
    this.hazmatNumberType = await this.$store.getters["load/lineItems/getLineItemsPicklists"]
      .hazmat_number_type;
    // eslint-disable-next-line max-len
    this.hazmatPackagingGroup = await this.$store.getters["load/lineItems/getLineItemsPicklists"]
      .hazmat_packaging_group;
    this.key += 1;
  },
  methods: {
    closeModal() {
      if (this.editMode) {
        this.$emit("closeModal", true);
      } else {
        this.$emit("closeModal", false);
      }
    },
    saveHazmatInformation() {
      const validation = this.validateFormfields();
      if (validation) {
        this.$emit("saveHazmatInformation", this.hazmat);
      }
    },
    validateFormfields() {
      const validatedForm = ValidateEmptyFields(this.hazmat);
      this.resetEmptyFieldsValues();
      const numberDigits = this.validateNumberDigits(this.hazmat.hazmat_number);
      validatedForm.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      if (validatedForm.validation && numberDigits) {
        this.$emit("input", this.hazmat);
        return true;
      }
      return false;
    },
    validateNumberDigits(number) {
      let validation = true;
      if (number) {
        if (number.length !== 4) {
          this.emptyFields.hazmat_number = true;
          validation = false;
        } else {
          this.emptyFields.hazmat_number = false;
        }
      }
      return validation;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../assets/scss/components/RCComponents.scss";

.hazmat {
  &-modal {
    padding: 20px;
    &__header {
      @include modals-header;
    }
    &__title {
      @include modals-title;
    }
  }
  &__content {
    &-inputs-container {
      margin: 30px auto;
      .input-container {
        position: relative
      }
    }
  }
}
.close-button {
  @include modals-close-button();
}
.button-container {
  @include primary-button(35px);
  padding: 4px;
  font-family: $font-family-portal;
}
hr {
  margin-top: 0;
}
.validation {
  color: red;
  font-size: 0.8rem;
  position: absolute;
  right: 0px;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}
::v-deep .bg-light {
  background-color: $color-gray-light !important;
}
</style>
