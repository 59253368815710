<template>
  <div class="location-information-info">
    <RCInput
      class="location-information-info__information"
      :class="emptyFields['name'] === true ? 'missing-field' : ''"
      :label="mode === 'pickup' ? $t('stopsInfo.pickupLocation') : $t('stopsInfo.deliveryLocation')"
      v-model="accountData.name"
      :placeholder="
        mode === 'pickup' ? $t('stopsInfo.pickupLocation') : $t('stopsInfo.deliveryLocation')
      "
      :floatLabel="true"
      @input="handleMissingStyles($event, 'name')"
    />
    <RCInput
      class="location-information-info__information"
      type="text"
      :class="emptyFields['street'] === true ? 'missing-field' : ''"
      :label="mode === 'pickup' ? $t('stopsInfo.shippingStreet') : $t('stopsInfo.DeliveryStreet')"
      :palceholder="
        mode === 'pickup' ? $t('stopsInfo.shippingStreet') : $t('stopsInfo.DeliveryStreet')
      "
      :floatLabel="true"
      v-model="accountData.street"
      @input="handleMissingStyles($event, 'street')"
    />
    <RCInput
      class="location-information-info__information"
      type="text"
      :class="emptyFields['addressInformation'] === true ? 'missing-field' : ''"
      :label="mode === 'pickup' ? $t('stopsInfo.shipping') : $t('stopsInfo.shippingDelivery')"
      :placeholder="mode === 'pickup' ? $t('stopsInfo.shipping') : $t('stopsInfo.shippingDelivery')"
      v-model="accountData.addressInformation"
      @input="handleMissingStyles($event, 'addressInformation')"
      :disabled="true"
      :floatLabel="true"
      style="background-color: #eeeeee"
    >
      <i class="ion ion-alert-circle-outline"></i>
    </RCInput>
    <AlertStreetValidations v-if="streetHaveSymbols" class="alert-error" />
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
import ValidateEmptyFields from "@/utils/validateEmptyFields";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import AlertStreetValidations from "../../../../components/AlertStreetValidations.vue";

export default {
  name: "AccountForm",
  components: {
    RCInput,
    AlertStreetValidations,
  },
  props: {
    mode: String,
    locationInformation: Object,
  },
  data() {
    return {
      accountData: {
        name: "",
        street: "",
        addressInformation: "",
      },
      emptyFields: {
        name: false,
        street: false,
        addressInformation: false,
      },
      zipcode: null,
      contactAccount: {},
      modalEditShow: false,
      loading: false,
      streetHaveSymbols: false,
    };
  },
  computed: {
    titleSelect() {
      if (this.locationInformation) {
        return this.locationInformation.name;
      }
      return "Create new location";
    },
  },
  async created() {
    this.accountData.addressInformation = `${this.locationInformation.city} / ${
      this.locationInformation.state_code
    } / ${this.locationInformation.zip_code || this.locationInformation.postal_code}`;
    this.accountData.city = this.locationInformation.city;
    this.accountData.state_code = this.locationInformation.state_code;
    this.zipcode = this.locationInformation.zip_code || this.locationInformation.postal_code;
  },
  watch: {
    "accountData.street": {
      handler(newValue) {
        const alphanumeric = /^[a-zA-Z0-9\s]*$/;
        if (!alphanumeric.test(newValue)) {
          this.streetHaveSymbols = true;
          this.emptyFields.street = true;
          return;
        }
        this.streetHaveSymbols = false;
        this.emptyFields.street = false;
      },
    },
  },
  methods: {
    validateFormfields() {
      const validatedForm = ValidateEmptyFields(this.accountData);
      this.resetEmptyFieldsValues();
      validatedForm.emptyFields.forEach((field) => {
        this.emptyFields[field] = true;
      });
      if (this.streetHaveSymbols) {
        this.emptyFields.street = true;
        return false;
      }
      if (validatedForm.validation) {
        this.$emit("input", this.accountData);
      }
      return validatedForm.validation;
    },
    resetEmptyFieldsValues() {
      const keys = Object.keys(this.emptyFields);
      keys.forEach((key) => {
        this.emptyFields[key] = false;
      });
    },
    handleMissingStyles(value, attribute) {
      if (value) {
        this.emptyFields[attribute] = false;
      } else {
        this.emptyFields[attribute] = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/scss/components/RCComponents.scss";

.location-information-info {
  display: flex;
  flex-direction: column;
  &__information {
    width: 100%;
  }
}
.button-content {
  padding: 15px;
  margin: auto;
  width: 90%;
  border-bottom: 1px solid $color-border-container;
  button {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    background: transparent;
    border: none;
    color: $color-primary-company;
    i {
      font-size: 22px;
      font-weight: bold;
    }
  }
}
.list-content {
  width: 90%;
  margin: 5px auto;
  &--section {
    color: $color-border-container;
    font-size: 0.89rem;
  }
  &--label {
    font-size: 0.95rem;
    cursor: pointer;
    padding: 3px;
    &:hover {
      background: rgba(128, 128, 128, 0.11);
    }
  }
}
::v-deep .modal-body {
  padding: 0px !important;
  border-radius: 15px;
}
::v-deep .spinner-border {
  color: $color-primary-company;
}
</style>
