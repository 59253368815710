<template>
  <div class="responsive-line-items">
    <h3 class="responsive-line-items__title" v-if="showTitle">Line item details</h3>
    <div v-if="isHazmat && !isAnyItemWithHazmat">
      <HazmatErrorNotification />
    </div>
    <div class="lineItems-container" v-for="(item, index) in data" :key="index">
      <div class="line-item__container">
        <h6 class="responsive-line-items__item-title">Item {{ index + 1 }}</h6>
        <div class="d-flex flex-column line-item-information">
          <div class="line-item-info nmfc-input" v-if="isReefer">
            {{ $t("accountTable.temperature") }}:
            <TemperatureComponent :isLineItemFTL="false" />
          </div>
          <div class="line-item-info">
            {{ $t("stopsInfo.commodity") }}:
            <input
              @blur="dataHandling(item.description, 'description', index)"
              class="input-information input-information--description"
              type="text"
              v-model="item.description"
              :placeholder="item.description"
            />
          </div>
          <div class="line-item-info nmfc-input">
            NMFC:
            <input
              @blur="dataHandling(item.nmfc_number, 'nmfc_number', index)"
              class="input-information input-information--description"
              type="text"
              v-model="item.nmfc_number"
              :placeholder="item.nmfc_number"
              :disabled="!item.nmfcFieldEnabled"
            />
            <span class="popover-container">
              <button
                class="tooltip-icon"
                type="button"
                :id="'lineitem-popover-responsive' + index"
              >
                <i class="ion ion-information-circle-outline"></i>
              </button>
              <b-popover
                ref="popover"
                :target="'lineitem-popover-responsive' + index"
                triggers="hover focus"
                placement="bottom"
              >
                <span>{{ item.nmfcPopover }}</span>
              </b-popover>
            </span>
          </div>
          <div class="line-item-info">
            {{ $t("stopsInfo.handlingUnits") }}
            <div>
              <p>{{ item.handling_unit_count }} {{ item.handling_units }}</p>
            </div>
          </div>
          <div class="line-item-info">
            {{ $t("stopsInfo.dimensions") }}
            <p>
              {{ ceilDecimals(item.dimensions.length) }} x
              {{ ceilDecimals(item.dimensions.height) }} x
              {{ ceilDecimals(item.dimensions.width) }} ({{ item.dimensions.dimension_units }})
            </p>
          </div>
          <div class="line-item-info">
            {{ $t("stopsInfo.weight") }}
            <p>{{ item.weight }} ({{ item.weight_units }})</p>
          </div>
          <div class="line-item-info">
            {{ $t("stopsInfo.nmfcClass") }}
            <p>{{ item.nmfc_class }}</p>
          </div>
          <div class="line-item-info">
            {{ $t("stopsInfo.packaging_unit_count") }}
            <input
              @blur="dataHandling(item.packaging_unit_count, 'packaging_unit_count', index)"
              class="input-information input-information--description"
              type="number"
              min="0"
              v-model="item.packaging_unit_count"
              :placeholder="$t('stopsInfo.packaging_unit_count')"
            />
          </div>
          <div class="line-item-info">
            {{ $t("allOptions.pallets") }}
            <div v-if="lineItemsPickList">
              <RCSelect
                class="packaging-units-dropdown"
                :suggestions="lineItemsPickList.packaging_units"
                :label="$t('allOptions.pallets')"
                v-model="item.packaging_units"
                @change="dataHandling(item.packaging_units, 'packaging_units', index)"
              />
            </div>
          </div>
          <div class="line-item-info" v-if="isHazmat">
            {{ $t("accountTable.hazardous_materials") }}
            <HazmatCheckbox
              :itemIsHazmat="item.hazardous_materials"
              :hazmatIndex="index"
              :hazmat="hazmatInformation[index]"
              :key="remountHazmatCheckbox"
              @removeHazmatInformation="
                removeHazmatInformation($event, index),
                  updateHazmatInformation($event, index),
                  findItemWithHazmatInfo()
              "
              @addHazmatInformation="
                addHazmatInformation($event, index),
                  updateHazmatInformation($event, index),
                  findItemWithHazmatInfo()
              "
              @isRequiredHazmat="isRequiredHazmat"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RCSelect from "../../../../../components/RCComponents/CustomRCSelect.vue";
import HazmatErrorNotification from "./hazmat/HazmatErrorNotification.vue";
import HazmatCheckbox from "./hazmat/HazmatCheckbox.vue";
import TenderLineItems from "../../../../../Extend/TenderLineItems";
import TemperatureComponent from "./TemperatureComponent.vue";

export default {
  name: "ResponsiveLineItems",
  props: {
    isHazmat: Boolean,
    data: Array,
    showTitle: Boolean,
    completeData: Array,
    isReefer: Boolean
  },
  mixins: [TenderLineItems],
  components: {
    RCSelect,
    HazmatErrorNotification,
    HazmatCheckbox,
    TemperatureComponent
  },
  data() {
    return {
      openedTabs: ["lineItems"],
      hazmatInformation: [],
      hazmatError: false,
      isAnyItemWithHazmat: false,
      lineItems: [],
      remountHazmatCheckbox: 0,
    };
  },
  async created() {
    if (!this.lineItemsPickList) {
      await this.$store.dispatch("load/lineItems/getLineItemPickList");
    }
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.lineItems = JSON.parse(JSON.stringify(this.data));
        this.getHazmatInformation();
        this.findItemWithHazmatInfo();
        // this.searchItemsWithHazmat();
      },
    },
  },
  computed: {
    lineItemsPickList: {
      get() {
        return this.$store.getters["load/lineItems/getLineItemsPicklists"];
      },
      set(value) {
        this.lineItemsPickList = value;
      },
    },
  },
  methods: {
    async dataHandling(value, key, index) {
      // this.searchItemsWithHazmat();
      if (value) {
        this.$store.commit("load/tender/setRequestInProcess", true);
        if (key === "description") {
          this.lineItems[index].description = value;
        }
        delete this.lineItems[index].density;
        delete this.lineItems[index].linear_feet;
        const payload = {
          id: this.$route.params.loadId,
          lineItemId: this.lineItems[index].line_item_id,
          body: {
            [key]: value,
          },
        };
        if (this.$options.filters.checkPermission("patch:line-item")) {
          await this.$store.dispatch("load/lineItems/updatedLineItem", payload);
        }
        this.$store.commit("load/tender/setRequestInProcess", false);
      }
    },
    addHazmatInformation(data, index) {
      this.isHazmatInformation = data;
      this.lineItems[index].hazardous_materials = true;
      this.lineItems[index] = { ...this.lineItems[index], ...data };
      this.updateVuexLineItems(
        {
          ...data,
          hazardous_materials: true,
          nmfcFieldEnabled: this.data[index].nmfcFieldEnabled,
          nmfcPopover: this.data[index].nmfcPopover,
        },
        index
      );
    },
    removeHazmatInformation(data, index) {
      this.lineItems[index].hazardous_materials = false;
      this.lineItems[index] = { ...this.lineItems[index], ...data };
      this.lineItems[index].hazmat_contact = "";
      this.updateVuexLineItems(
        {
          ...data,
          hazardous_materials: false,
          nmfcFieldEnabled: this.data[index].nmfcFieldEnabled,
          nmfcPopover: this.data[index].nmfcPopover,
        },
        index
      );
    },
    updateLoadLineItems() {
      return [Promise.resolve()];
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-line-items {
  color: $color-primary-company;
  @include font-small-standart-text;
  width: 95%;
  margin: auto;
  &__item-title {
    @media (max-width: 800px) {
      font-size: 14px;
    }
  }
  &__title {
    padding-top: 15px;
    padding-bottom: 10px;
    text-align: start;
    font-size: 14px;
    font-weight: 700;
    border-top: 1px solid $color-border-container;
    color: $color-border-container;
  }
}
.line-item-info {
  @include hide-input-number-arrows;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  align-items: center;
  p {
    font-weight: normal;
    margin-bottom: 10px;
  }
}
.line-item {
  &__container {
    margin-top: 15px;
    text-align: start;
    .input-information--description {
      width: auto;
      height: 30px;
      margin-left: 20px;
      padding-left: 10px;
      border: 1px solid $color-border-container;
      border-radius: 10px;
      &:focus {
        outline: none;
      }
    }
    h6 {
      color: $color-border-container;
    }
  }
  &-information {
    gap: 10px;
  }
}

.nmfc-input {
  position: relative;
  margin-top: 6px;
}

.popover-container {
  position: absolute;
  top: 5px;
  right: 7px;
}

button {
  border: none;
  background: none;
  padding: 0;
  color: $color-primary-company;
  cursor: initial !important;
}

::v-deep .packaging-units-dropdown {
  width: 160px;
  font-weight: normal;
  & .rc-select-container {
    height: 30px;
  }
  & .rc-floating-label,
  .rc-select,
  .rc-suggestions-list {
    font-size: 14px;
  }
  & .rc-suggestions {
    top: 30px;
  }
}

::v-deep {
  .rc-input-container {
    height: 30px;
  }
  .rc-select-container {
    height: 30px;
  }
  .rc-select,
  .rc-suggestions-list__item {
    font-weight: normal;
    font-size: 14px;
  }
}

</style>
