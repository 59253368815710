<template>
  <div class="line-items-data">
    <div class="line-items-content">
      <div v-if="isHazmat && !isAnyItemWithHazmat">
        <HazmatErrorNotification />
      </div>
      <div class="line-items__title">
        <span>{{ $t("stopsInfo.lineItemDetail") }}</span>
      </div>
      <div class="line-items-container">
        <!-- Line Items Section -->
        <div class="table-line-items">
          <b-overlay :show="loading" rounded="sm" class="overlay">
            <TableHeaderContainer
              :fields="lineItemsFields"
              :styles="styleObject"
              class="table-header"
            />
            <div class="table">
              <TableRowContent
                v-if="hazmatInformation"
                :lineItems="data"
                @handlingLoad="handlingLoad"
                @searchItemsWithHazmat="findItemWithHazmatInfo"
                @isRequiredHazmat="isRequiredHazmat"
                :hazmatInformation="hazmatInformation"
                :isHazmat="isHazmat"
                :isReefer="isReefer"
                :completeData="completeData"
              />
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableHeaderContainer from "@/components/TableHeaderContainer.vue";
import HazmatErrorNotification from "./hazmat/HazmatErrorNotification.vue";
import TableRowContent from "./TableRowContent.vue";
import TenderLineItems from "../../../../../Extend/TenderLineItems";

export default {
  name: "LineItems",
  props: {
    data: Array,
    isHazmat: Boolean,
    lineItemsFields: Array,
    completeData: Array,
    isReefer: Boolean
  },
  mixins: [TenderLineItems],
  components: {
    TableHeaderContainer,
    HazmatErrorNotification,
    TableRowContent,
  },
  data() {
    return {
      styleObject: {
        width: "14.58%",
      },
      lineItems: [],
      hazmatInformation: [],
      hazmatError: false,
      isAnyItemWithHazmat: false,
      loading: true,
    };
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.lineItems = JSON.parse(JSON.stringify(this.data));
        for (let i = 0; i < this.data.length; i += 1) {
          delete this.lineItems[i].line_item_id;
        }
        this.getHazmatInformation();
        this.findItemWithHazmatInfo();
      },
    },
  },
  methods: {
    handlingLoad(data) {
      this.loading = data;
    },
    updateLoadLineItems() {
      return [Promise.resolve()];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-line-items {
  width: 100%;
}
.line-items {
  &-content {
    margin: 20px 30px;
    // width: 90%;
  }
  &__title {
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    margin: 20px 0px;
    color: $color-border-container;
  }
}
.table {
  @include font-small-standart-text;
}
.input-information {
  @include input-password;
  width: 100%;
  @include font-small-standart-text;
  color: $color-primary-company;
  border-radius: 10px;
  &--description {
    border: 1px solid $color-border-container;
    text-align: center;
    width: 100%;
  }
}
.line-items-container {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}
::v-deep {
  .table-header {
    padding: 0px;
  }
  .table-header-titles {
    margin: 0;
  }
  .table-row-content {
    padding: 0px;
  }
  .dropdown {
    width: 88%;
    height: 33px;
  }
  .dropdown-container__selected-option {
    max-height: 31px;
    font-size: 14px;
  }
  .dropdown-container__option {
    font-size: 14px;
  }
}
::v-deep table {
  width: 95%;
  margin: auto;
}
::v-deep table tbody td {
  width: 12.5% !important;
}
::v-deep .table-header-titles {
  display: block;
}
::v-deep .table .thead-light {
  display: none;
}
::v-deep .table-responsive {
  max-height: 60vh;
  @extend %scroll-bar-styles;
}
::v-deep .table td {
  font-weight: 600;
  color: $color-primary-company;
  border-bottom: 1px solid $color-border-container !important;
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: none;
  width: 14.58%;
}
</style>
